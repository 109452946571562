import '../../css/questionnaire.css';
import axios from 'axios';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import State from '../data/State';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import User from '../data/User';
import { screen } from '../types/enums';

enum typeModal {
  SIZE,
  TYPE,
  DONE
}

export default observer(() => {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<typeModal>(null);
  const [lastname, setLastname] = useState('');
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('+7');
  const [mail, setMail] = useState('');
  const [about, setAbout] = useState('');
  const [link, setLink] = useState('');
  const [isLink, setLinkType] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [file, setFile] = useState<File>(null);
  const [waiting, setWaiting] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegExp = /^\+7\d{10}$/;

  const prepareData = () => {
    if (waiting) return;
    const testMail = emailRegExp.test(mail);
    const testPhone = phoneRegExp.test(phone);
    !testMail && setMailError(true);
    !testPhone && setPhoneError(true);

    if (testMail && testPhone) {
      setWaiting(true);
      const data = {
        lastname: lastname,
        name: name,
        city: city,
        phone: phone,
        mail: mail,
        about: about,
        link: link
      }

      if (!isLink) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('id', User.getID().toString());
        formData.append('init_data', User.getInitData());
        axios.post(process.env.REACT_APP_API + '/upload', formData).then(res => {
          if (!res.data.error) {
            data.link = res.data.data;
            sendData(data);
          } else {
            State.setScreen(screen.ERROR);
          }
        });
      } else {
        sendData(data);
      }
    }
  }

  const sendData = (data: {[key: string]: string }) => {
    axios.post(process.env.REACT_APP_API + '/sendQuestionnaire', {
      init_data: User.getInitData(),
      id: User.getID(),
      data: data
    }).then(res => {
      if (!res.data.error) {
        setModalType(typeModal.DONE);
        setShowModal(true);
      } else {
        State.setScreen(screen.ERROR);
      }
    }).catch(e => State.setScreen(screen.ERROR));
  }

  const handleSelectedFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files[0];

    if (file) {
      
      if ((file?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file?.type === 'application/pdf') && (!/.jfif/i.test(file?.name))) {

        if (file.size / 1024 < 10240) {
          setFile(file);
        } else {
          setModalType(typeModal.SIZE);
          setShowModal(true);
        }
      } else {
        setModalType(typeModal.TYPE);
        setShowModal(true);
      }
    }
  }

  const linkBlock = (
    <div className='questionnaire-input-block'>
      <div className='questionnaire-input-label'>Ссылка</div>
      <input className='questionnaire-input' value={ link } onChange={e => setLink(e.target.value)} placeholder='hh, superjob и другие' type='text' />
    </div>
  );

  const fileBlock = (
    <>
      { file === null ? 
      <div className='questionnaire-file-block'>
        <input accept='application/pdf, .doc, .docx' type='file' id='file' style={{ display: 'none' }} onChange={ handleSelectedFile } />
        <div className='questionnaire-file-choose-button' onClick={() => {
          if (waiting) return;
          const input: HTMLInputElement = document.querySelector('#file');
          input.click();
        }}>Выберите файл </div>
        <div className='questionnaire-file-choose-descr'>Один файл размером до 10 МБ</div>
      </div>
      :
      <div className='questionnaire-input-block'>
        <div className='questionnaire-input-label'>Резюме</div>
        <div className='questionnaire-filename-block'>
          <div className='questionnaire-filename'>{ file.name.length > 20 ? '...' + file.name.slice(-15) : file.name }</div>
          <div className='questionnaire-file-cancel' onClick={() => setFile(null)}></div>
        </div>
      </div> }
    </>
  );

  const modal = () => {
    const text = modalType === typeModal.TYPE ? 'Неподдерживаемый тип файла' : modalType === typeModal.SIZE ? 'Слишком большой размер файла' : 'Ваша заявка принята!';
    const button = modalType === typeModal.DONE ? 'На главную' : 'Закрыть';
    return (
      <div className='questionnaire-modal-block'>
        <div className='questionnaire-modal'>
          <div className='questionnaire-modal-text'>{ text }</div>
          <div className='questionnaire-modal-buttons'>
            <div onClick={() => {
              if (modalType === typeModal.DONE) {
                State.setScreen(screen.MAIN);
              } else {
                setShowModal(false);
              }              
            }}>{ button }</div>
          </div>
        </div>
      </div>
    );
  }

  const setFormatPhone = (phone: string) => {
    if (phone.length < 3) {

      if (phone !== '+7' && phone !== '+' && phone !== '') {
        phone = '+7';
      }
    } else {

      if (phone[0] !== '+') {
        phone = '+' + phone;
      }

      if (phone[1] !== '7') {
        phone = phone[0] + '7' + phone.slice(2);
      }
      phone = '+7' + phone.slice(2).replace(/[^0-9]/g, '');

      if (phone.length > 12) {
        phone = phone.slice(0, 12);
      }
    }
    setPhone(phone);
  }

  const questionnaire = Boolean(isLink ? link : file);
  const sendActive = Boolean(lastname && name && city && phone && mail && about && questionnaire && agreement) || waiting;
  const buttonName = waiting ? 'Подождите' : 'Отправить заявку';

  return (
    <div className='questionnaire-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='questionnaire-team-block'>
        <div className='questionnaire-team-title'>Стать частью<br />команды</div>
        <div className='questionnaire-team-santo'>Santo stefano</div>
      </div>
      <div className='questionnaire-data-block'>
        <div className='questionnaire-input-block'>
          <div className='questionnaire-input-label'>Фамилия</div>
          <input className='questionnaire-input' value={ lastname } onChange={e => setLastname(e.target.value)} placeholder='Иванов' type='text' />
        </div>
        <div className='questionnaire-input-block'>
          <div className='questionnaire-input-label'>Имя</div>
          <input className='questionnaire-input' value={ name } onChange={e => setName(e.target.value)} placeholder='Иван' type='text' />
        </div>
        <div className='questionnaire-input-block'>
          <div className='questionnaire-input-label'>Город</div>
          <input className='questionnaire-input' value={ city } onChange={e => setCity(e.target.value)} placeholder='Москва' type='text' />
        </div>
        <div className='questionnaire-input-block'>
          <div className='questionnaire-input-label'>Телефон</div>
          <input className={'questionnaire-input' + (phoneError ? ' questionnaire-error' : '')} value={ phone } onChange={e => {
            setPhoneError(false);
            setFormatPhone(e.target.value);
          }} placeholder='+79000000000' type='text' />
        </div>
        <div className='questionnaire-input-block'>
          <div className='questionnaire-input-label'>email</div>
          <input className={'questionnaire-input' + (mailError ? ' questionnaire-error' : '')} value={ mail } onChange={e => {
            setMailError(false);
            setMail(e.target.value);
          }} placeholder='example@mail.ru' type='text' />
        </div>
        <div className='questionnaire-switch-block'>
          <div style={{ backgroundColor: !isLink ? '#FFFFFF' : null }} className='questionnaire-switch-button' onClick={() => {
            if (waiting) return;
            setLinkType(false);
          }}>Файл с резюме</div>
          <div style={{ backgroundColor: isLink ? '#FFFFFF' : null }}  className='questionnaire-switch-button' onClick={() => {
            if (waiting) return;
            setLinkType(true);
          }}>Ссылка на резюме</div>
        </div>
        { isLink ? linkBlock : fileBlock }
        <div className='questionnaire-input-block'>
          <div className='questionnaire-input-label'>Расскажи о себе</div>
          <textarea
            placeholder='О себе'
            className='questionnaire-textarea'
            onChange={e => setAbout(e.target.value)}
            defaultValue={ about }
          ></textarea>
        </div>
        <div className='questionnaire-checkbox-block'>
          <div className={'questionnaire-checkbox reg-checkbox-' + (agreement ? 'enable' : 'disable')} onClick={() => setAgreement(!agreement)}></div>
          <a href={ State.AGREEMENT } target='_blank'>Пользовательское соглашение</a>
        </div>
        <div className='questionnaire-button-block'>
          <div className='gold-button' style={{ opacity: sendActive ? 1 : .5 }} onClick={() => {
            if (!sendActive) return;
            prepareData();
          }}>{ buttonName }</div>
        </div>
      </div>
      { showModal && modal() }
    </div>
  );
});