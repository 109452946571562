import '../../css/faq.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import State from '../data/State';
import faq from '../data/faq';
import { screen } from '../types/enums';

export default observer(() => {
  const [active, setActive] = useState<number>(null);

  const list = faq.map(data => {
    if (active === data.id) {
      return (
        <div key={ data.id } className='faq-item-full'>
          <div className='faq-item-header' onClick={() => setActive(null)}>{ data.header }</div>
          <div className='faq-item-descr' dangerouslySetInnerHTML={{ __html: data.descr }}></div>
        </div>
      );
    } else {
      return (
        <div
          key={ data.id }
          className='faq-item-short'
          onClick={() => active === data.id ? setActive(null) : setActive(data.id)}
        >
          { data.header }
        </div>
      );
    }
  });

  return (
    <div className='faq-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='faq-header'>FAQ</div>
      { list }
      <div className='faq-support-descr'>Если вы не нашли ответ на свой вопрос, напишите нам</div>
      <div className='faq-button-block'>
        <div className='gold-button' onClick={() => State.setScreen(screen.SUPPORT)}>Написать в поддержку</div>
      </div>
      <div className='faq-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
    </div>
  );
});