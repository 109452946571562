import '../../css/carousel-products.css';
import allProducts from '../data/products';
import State from '../data/State';
import { screen, productFilter } from '../types/enums';

const START_POSITION = -75;
const INDENT = 50;
const DELAY = 700;
const SWIPE_VALUE = 10;

const products = [
  { ...allProducts[9], id: 2 },
  { ...allProducts[0], id: 3 },
  { ...allProducts[17], id: 1 },
  { ...allProducts[9], id: 5 },
  { ...allProducts[0], id: 6 },
  { ...allProducts[17], id: 4 }
];

export default () => {
  const first = START_POSITION;
  const last = START_POSITION + (products.length - 1) * INDENT;
  let disable: boolean = false;
  let touchPosition: number = null;

  const toLeft = () => {
    if (disable) return;
    disable = true;
    products.map(item => {
      const card: HTMLDivElement = document.querySelector('#card-product-' + item.id);
      const left = Number(card.style.left.slice(0, -2));

      if (left !== first) {
        card.style.transition = 'all ' + DELAY + 'ms ease-in-out';
        card.style.left = (left - INDENT) + 'vw';
      } else {
        card.style.left = last + 'vw';
      }
      const scale = left === 75 ? '1' : '0.8';
      card.style.scale = scale;
    });
    update();
  }

  const toRight = () => {
    if (disable) return;
    disable = true;
    products.map(item => {
      const card: HTMLDivElement = document.querySelector('#card-product-' + item.id);
      const left = Number(card.style.left.slice(0, -2));

      if (left !== last) {
        card.style.transition = 'all ' + DELAY + 'ms ease-in-out';
        card.style.left = (left + INDENT) + 'vw';
      } else {
        card.style.left = first + 'vw';
      }
      const scale = left === -25 ? '1' : '0.8';
      card.style.scale = scale;
    });
    update();
  }

  const update = () => {
    setTimeout(() => {
      products.map(item => {
        const card: HTMLDivElement = document.querySelector('#card-product-' + item.id);
        card && (card.style.transition = 'none');
      });
      disable = false;
    }, DELAY);
  }

  const getName = (tag: productFilter): string => {
    if (productFilter.NON_ALCOHOLIC === tag) return 'Безалкогольное';
    if (productFilter.VERMOUTH === tag) return 'Вермуты';
    if (productFilter.SPARKLING === tag) return 'Игристое вино';
  }

  const carousel = products.map((item, index) => {
    const position = START_POSITION + index * INDENT;
    const scale = position === 25 ? 1 : .8;
    return (
      <div
        key={ item.id }
        id={'card-product-' + item.id}
        className='carousel-product-card'
        style={{
          left: position.toString() + 'vw',
          scale: scale.toString()
        }}
        onClick={(e) => {
          const div = document.querySelector('#card-product-' + item.id) as HTMLDivElement
          if (div?.style?.scale === '1') {
            State.setProductFilter(item.tag);
            State.setScreen(screen.PRODUCTS);
          }
        }}
      >
        <div className='carousel-product-card-content'>{ getName(item.tag) }</div>
        <div className='carousel-product-card-image' style={{ backgroundImage: 'url( ' + item.image + ')' }}></div>
      </div>
    );
  });

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchPosition = e.touches[0].clientX;
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition
    if (touchDown === null) return;
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > SWIPE_VALUE) toLeft();
    if (diff < -SWIPE_VALUE) toRight();
    touchPosition = null;
  }

  return (
    <div className='carousel-products-block' onTouchStart={ handleTouchStart } onTouchMove={ handleTouchMove }>
      { carousel }
      <div className='carousel-product-button-left' onClick={ toRight }></div>
      <div className='carousel-product-button-right' onClick={ toLeft }></div>
    </div>
  );
}