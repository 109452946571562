import '../../css/scan-qr.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Html5Qrcode } from 'html5-qrcode';
import User from '../data/User';
import State from '../data/State';
import ResultScan from '../components/ResultScan';
import { screen } from '../types/enums';

enum pages {
  DESCR,
  SCAN,
  PERM,
  RES
}

export default observer(() => {
  const [page, setPage] = useState<pages>(pages.SCAN);
  const [success, setSuccess] = useState(false);
  
  useEffect(() => {
    if (page !== pages.SCAN) return;
    State.setScanWaiting(false);
    const size = document.querySelector('#root').clientWidth * .61;
    const html5QrCode = new Html5Qrcode('scan-qr-reader', false);
    State.setScanner(html5QrCode);
    html5QrCode.start({ facingMode: 'environment' }, {
      fps: 30,
      qrbox: {
        width: size,
        height: size
      }
    }, onScanSuccess, () => {
      html5QrCode.isScanning && State.getScreen() !== screen.SCAN_QR && html5QrCode.stop();
    }).catch(err => {
      setPage(pages.PERM);
    });
  }, [page]);

  
  const onScanSuccess = (data: string) => {
    if (!State.isScanWaiting()) {
      State.setScanWaiting(true);
      sendBill(data);
    }
  }

  const sendBill = (data: string) => {
    axios.post(process.env.REACT_APP_API + '/sendBill', {
      init_data: User.getInitData(),
      id: User.getID(),
      data: data
    }).then(res => {
      if (!res.data.error) {
        const scores = User.getScores() + res.data.data.points;
        res.data.data.success && User.setScores(scores);
        setSuccess(res.data.data.success);
        setPage(pages.RES);
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
      State.getScanner().stop();
    }).catch(e => {
      State.setScreen(screen.ERROR);
    });
  }

  const scanning = (
    <>
      <div className='scan-qr-scanning-block'>
        <div id='scan-qr-reader'></div>
      </div>
      <div className='scan-qr-bg-block'>
        <div className='scan-qr-bg-top'></div>
        <div className='scan-qr-bg-bottom'></div>
        <div className='scan-qr-bg-left'></div>
        <div className='scan-qr-bg-right'></div>
        <div className='scan-qr-camera'></div>
        <div className='scan-qr-button-block'>
          <div className='gold-button' onClick={() => {
            const scanner = State.getScanner();
            scanner.isScanning && scanner.stop();
            State.setScreen(screen.MAIN);
          }}>На главную</div>
        </div>
      </div>
    </>
  );

  const descr = (
    <div className='scan-qr-descr-block'>
      <div className='scan-qr-descr-image'></div>
      <div className='scan-qr-descr-button-block'>
        <div className='scan-qr-descr-button' onClick={() => setPage(pages.SCAN)}>Понятно</div>
      </div>
    </div>
  );

  const permissions = (
    <div className='scan-qr-permissions'>
      <div>Необходимо подключение к камере</div>
      <div className='scan-qr-button-block'>
          <div className='gold-button' onClick={() => State.setScreen(screen.MAIN)}>На главную</div>
        </div>
    </div>
  );

  const result = (
    <ResultScan
      success={ success }
      callback={() => {
        State.setScanWaiting(false);
        setPage(pages.SCAN);
      }}
    />
  );

  if (page === pages.SCAN) {
    return scanning;
  } else if (page === pages.DESCR) {
    return descr;
  } else if (page === pages.RES) {
    return result;
  } else {
    return permissions;
  }
});