import '../../css/scan-choose.css';
import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import State from '../data/State';

export default observer(() => {
  return (
    <div className='scan-choose-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='scan-choose-descr'>Наведите камеру смартфона на QR-код чека</div>
      <div className='scan-choose-qr'></div>
      <div className='scan-choose-buttons-block'>
        <div className='gold-button' onClick={() => State.setScreen(screen.SCAN_QR)}>Сканировать</div>
        <div className='white-button' onClick={() => State.setScreen(screen.SCAN_MANUAL)}>Ввести вручную</div>
      </div>
    </div>
  );
});