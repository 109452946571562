import '../../css/merch.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import State from '../data/State';
import Profile from '../data/Profile';
import merch from '../data/merch';
import { screen, merchFilter, sizeFilter } from '../types/enums';

export default observer(() => {
  const [size, setSize] = useState<sizeFilter>(sizeFilter.S);
  const product = merch.find(data => data.id === State.getActiveMerch());
  const favorite = Boolean(Profile.getMerchFavorites().find(data => data === product.id));
  const favIcon = favorite ? 'enable' : 'disable';
  const tagName = product.tag === merchFilter.EXCLUSIVE ? 'Эксклюзив' : 'Новинка';
  const cart = Profile.getCart().find(data => data.id === product.id);

  const chooseSize = (size: sizeFilter) => {
    if (cart) {
      Profile.setCart({
        id: product.id,
        count: cart.count,
        size: size
      });
    }
    setSize(size);
  }

  return (
    <div className='merch-block'>
      <HeaderLogo />
      <BackButton callback={() => State.toBackScreen()} />
      <div className={'merch-fav-icon ' + favIcon} onClick={() => favorite ? Profile.removeMerchFavorite(product.id) : Profile.addMerchFavorite(product.id)}></div>
      <div className='merch-cover' style={{ backgroundImage: 'url(' + product.image + ')' }}>
        { product.tag && <div className='merch-tag'>{ tagName }</div> }
      </div>
      <div className='merch-content'>
        <div className='merch-name'>{ product.name.toUpperCase() }</div>
        <div className='merch-price'>{ product.price } Б { product.discount && <span>{ product.discount } Б</span> }</div>
        <div className='merch-descr' dangerouslySetInnerHTML={
          { __html: product.descr }
        }></div>
        {/* <div className='merch-size-text'>Размер</div>
        <div className='merch-size-buttons-block'>
          <div className={'merch-size-button ' + (size === sizeFilter.XS ? 'enable' : 'disable')} onClick={() => chooseSize(sizeFilter.XS)}>XS</div>
          <div className={'merch-size-button ' + (size === sizeFilter.S ? 'enable' : 'disable')} onClick={() => chooseSize(sizeFilter.S)}>S</div>
          <div className={'merch-size-button ' + (size === sizeFilter.M ? 'enable' : 'disable')} onClick={() => chooseSize(sizeFilter.M)}>M</div>
          <div className={'merch-size-button ' + (size === sizeFilter.L ? 'enable' : 'disable')} onClick={() => chooseSize(sizeFilter.L)}>L</div>
          <div className={'merch-size-button ' + (size === sizeFilter.XL ? 'enable' : 'disable')} onClick={() => chooseSize(sizeFilter.XL)}>XL</div>
        </div> */}
      </div>
      { !cart && <div className='merch-cart-button' onClick={() => {
        Profile.setCart({
          id: product.id,
          size: size,
          count: 1
        });
      }}>В корзину</div> }
      { cart && <div className='merch-cart-count-block'>
        <div className='merch-to-cart-button' onClick={() => State.setScreen(screen.CART)}>Перейти в корзину</div>
        <div className='merch-count-block'>
          <div className='merch-count-minus' onClick={() => {
            if (cart.count === 1) {
              Profile.removeCart(product.id);
            } else {
              Profile.setCart({
                id: product.id,
                count: cart.count - 1,
                size: size
              });
            }
          }}></div>
          <div className='merch-count-text'>{ cart.count }</div>
          <div className='merch-count-plus' onClick={() => {
            Profile.setCart({
              id: product.id,
              count: cart.count + 1,
              size: size
            });
          }}></div>
        </div>
      </div> }
      <div className='merch-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
    </div>
  );
});