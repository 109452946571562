import '../../css/stories.css';
import { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import State from '../data/State';
import poster from '../../assets/media/stories/poster-video.png';

enum status {
  DONE,
  ACTIVE,
  PASSIVE
}
const TIME = 10;

export default observer(() => {
  // const [showDescr, setShowDescr] = useState(false);
  const [showDescr, setShowDescr] = useState(true);
  const [timeoutEvent, setTimeoutEvent] = useState<NodeJS.Timeout>(null);
  const videoRef = useRef(null);
  const story = State.getStories()[State.getActiveStory()];

  useEffect(() => {
    if (story.video) return;
    const timeout = setTimeout(() => setNext(), TIME * 1000);
    setTimeoutEvent(timeout);
  }, [State.getActiveStory()]);

  const setNext = () => {
    clearTimeout(timeoutEvent);
    // setShowDescr(false);
    State.getActiveStory() === State.getStories().length - 1 ? close() : State.setActiveStory(State.getActiveStory() + 1);
    videoRef.current?.load();
  }

  const close = () => {
    clearTimeout(timeoutEvent);
    State.setShowStories(false);
  }

  const indents = State.getStories().length - 1;
  const width = (100 - indents * 7) / State.getStories().length;
  const progress = State.getStories().map((data, index) => {
    const progress: status =
      index === State.getActiveStory() ? status.ACTIVE :
      index < State.getActiveStory() ? status.DONE :
      status.PASSIVE;
    return (
      <div key={ data.id } className='story-header-progress-bg' style={{ width: `${width}%` }}>
        { progress === status.ACTIVE ? <div className='story-header-progress story-header-progress-animation'></div> :
        progress === status.DONE ? <div className='story-header-progress' style={{ width: '100%' }}></div> :
        null }
      </div>
    );
  });

  const descr = showDescr ? story.descr : story.descr.replace(/<[^>]*>/g, '').slice(0, 100) + '...';

  return (
    <div className='story-block'>
      <div className='story-content' onClick={() => setNext()}>

        { story.video ?
        <video ref={ videoRef } className='story-video' playsInline autoPlay loop poster={ story.poster }>
          <source src={ story.src } type='video/mp4' />
        </video>
        :
        <div className='story-image' style={{ backgroundImage: 'url(' + story.src + ')' }}></div> }

        <div className='story-header-block'>
          <div className='story-header-progress-block'>
            { progress }
          </div>
          <div className='story-close-button' onClick={e => {
            e.stopPropagation();
            close();
          }} />
        </div>
        <div
          className='story-descr'
          dangerouslySetInnerHTML={{ __html: descr }}
          onClick={e => {
            // e.stopPropagation();
            // setShowDescr(!showDescr);
          }}
        ></div>
      </div>
    </div>
  );
});