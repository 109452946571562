const faq: Ifaq[] = [
  {
    id: 1,
    header: 'КТО МОЖЕТ ПОЛЬЗОВАТЬСЯ ПРИЛОЖЕНИЕМ?',
    descr: 'Дееспособные лица от 18 лет, постоянно проживающие на территории РФ.'
  },
  {
    id: 2,
    header: 'КАК КУПИТЬ МЕРЧ?',
    descr: '- Купить продукцию Santo Stefano;</br>- Зарегистрироваться в чат-боте Telegram;</br>- Сфотографировать чек и загрузить его через мини-приложение в Telegram чат-боте;</br>- Получить баллы за продукцию в чеке;</br>- Потратить баллы на понравившийся мерч;</br>- Указать корректные контактные данные для доставки мерча.'
  },
  {
    id: 3,
    header: 'В КАКИХ МАГАЗИНАХ Я МОГУ КУПИТЬ ПРОДУКЦИЮ SANTO STEFANO?',
    descr: 'Во всех розничных магазинах на территории Российской Федерации, соблюдающих все требования налогового и гражданского законодательства Российской Федерации.o'
  },
  {
    id: 4,
    header: 'ЗА КАКУЮ ПРОДУКЦИЮ МОЖНО ПОЛУЧИТЬ БАЛЛЫ?',
    descr: '• Santo Stefano Bianco 0,75 л.<br />• Santo Stefano Bianco Amabile Gold 0,75 л.<br />• Santo Stefano Vermouth Rosato 1 л.<br />• Santo Stefano Bianco 0,25 л.<br />• Santo Stefano Rose 0,75 л.<br />• Santo Stefano Rose 0,25 л.<br />• Santo Stefano Rosso 0,75 л.<br />• Santo Stefano Rosso 0,25 л.<br />• Santo Stefano Matrimonio Siciliano 0,75 л.<br />• Santo Stefano Matrimonio Siciliano 0,25 л.<br />• Santo Stefano Vacanze Romane 0,75 л.<br />• Santo Stefano Aperini Spritz 0,75 л.<br />• Santo Stefano Bianco п/сух 0,75 л.<br />• Santo Stefano Bianco 1 л.<br />• Santo Stefano Bianco 0,75 л.<br />• Santo Stefano Bianco 0,5 л.<br />• Santo Stefano Aperini 1 л.<br />• Santo Stefano Aperini 0,75 л.<br />• Santo Stefano Bianco Zero 0,75 л.<br />• Santo Stefano Bianco Zero 0,25 л.<br />• Santo Stefano Rosso Zero 0,75 л.<br />• Santo Stefano Rosso Zero 0,25 л.<br />• Santo Stefano Brut 0,75 л.<br />• Santo Stefano Brut Rose 0,75 л.'
  },
  {
    id: 5,
    header: 'ПО КАКОМУ ПРИНЦИПУ НАЧИСЛЯЮТСЯ БАЛЛЫ ЗА ПОКУПКИ SANTO STEFANO?',
    descr: 'Все просто, баллы начисляются согласно следующим правилам:<br />• Santo Stefano Bianco 0,75 л. = 20 баллов<br />• Santo Stefano Bianco Amabile Gold 0,75 л. = 20 баллов<br />• Santo Stefano Vermouth Rosato 1 л. = 30 баллов<br />• Santo Stefano Bianco 0,25 л. = 7 баллов<br />• Santo Stefano Rose 0,75 л. = 20 баллов<br />• Santo Stefano Rose 0,25 л. = 7 баллов<br />• Santo Stefano Rosso 0,75 л. = 20 баллов<br />• Santo Stefano Rosso 0,25 л. = 7 баллов<br />• Santo Stefano Matrimonio Siciliano 0,75 л. = 20 баллов<br />• Santo Stefano Matrimonio Siciliano 0,25 л. = 7 баллов<br />• Santo Stefano Vacanze Romane 0,75 л. = 20 баллов<br />• Santo Stefano Aperini Spritz 0,75 л. = 20 баллов<br />• Santo Stefano Bianco п/сух 0,75 л. = 20 баллов<br />• Santo Stefano Bianco 1 л. = 30 баллов<br />• Santo Stefano Bianco 0,75 л. = 22 балла<br />• Santo Stefano Bianco 0,5 л.= 15 баллов<br />• Santo Stefano Aperini 1 л. = 30 баллов<br />• Santo Stefano Aperini 0,75 л. = 22 балла<br />• Santo Stefano Bianco Zero 0,75 л. = 20 баллов<br />• Santo Stefano Bianco Zero 0,25 л. = 8 баллов<br />• Santo Stefano Rosso Zero 0,75 л. = 20 баллов<br />• Santo Stefano Rosso Zero 0,25 л. = 8 баллов<br />• Santo Stefano Brut 0,75 л. = 20 баллов<br />• Santo Stefano Brut Rose 0,75 л. = 20 баллов'
  },
  {
    id: 6,
    header: 'ЧТО ДЕЛАТЬ, ЕСЛИ У МЕНЯ НЕСТАНДАРТНЫЙ ВОПРОС, НА КОТОРЫЙ НЕ НАШЕЛСЯ ОТВЕТ В СПИСКЕ?',
    descr: 'Вы можете обратиться в службу поддержки Santo Stefano через раздел личного кабинета, мы обязательно ответим на ваш вопрос в самое ближайшее время.'
  },
  {
    id: 7,
    header: 'Я ОФОРМИЛ ЗАКАЗ МЕРЧА, ЧТО ПОТОМ?',
    descr: 'После оформления заказа через приложение вы увидите номер и статус заказа в личном кабинете. Отслеживание возможно на сайте транспортной компании, выполняющей доставку.'
  },
  {
    id: 8,
    header: 'НЕ МОГУ ОТСКАНИРОВАТЬ ЧЕК',
    descr: 'Если у вас проблема со сканированием, проверьте ваше интернет-соединение, не включен ли на устройстве VPN, тот ли QR-код вы сканируете (бывает, что на чеке два QR-кода), и подходит ли чек под условия.<br />Если проблема сохраняется, напишите нам в тех.поддержку.'
  }
];

export default faq;