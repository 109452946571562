import recipe1 from '../../assets/media/recipes/recipe-1.png';
import recipe2 from '../../assets/media/recipes/recipe-2.png';
import recipe3 from '../../assets/media/recipes/recipe-3.png';
import video1 from '../../assets/media/recipes/recipe-1.mp4';

import recipePoster1 from '../../assets/media/recipes/recipe-poster-1.png';

const recipes: Irecipe[] = [
  {
    id: 1,
    image: recipe1,
    shortName: 'Aperini & Tonic',
    name: 'Santo Stefano Aperini & Tonic',
    alcohol: '13.5%',
    complexity: 'Простой',
    time: '5 минут',
    taste: 'Сладкий',
    ingredients: 'Лёд (кубики) – 200 гр.<br />Santo Stefano Aperini – 100 мл.<br />Indian Tonic – 100 мл.<br />Долька апельсина',
    descr: 'Берем бокал, наполняем бокал льдом, наливаем Santo Stefano Aperini, добавляем индиан тоник, перемешиваем коктейль, добавляем дольку апельсина',
    video: video1,
    poster: recipePoster1
  },
  {
    id: 2,
    image: recipe2,
    shortName: 'Bianco & Tonic',
    name: 'Santo Stefano Bianco & Tonic',
    alcohol: '13.5%',
    complexity: 'Простой',
    time: '5 минут',
    ingredients: 'Лёд (кубики) – 200 гр.<br />Santo Stefano Bianco – 100 мл.<br />Indian Tonic – 100 мл.<br />Долька лайма',
    descr: 'Берем бокал, наполняем бокал льдом, наливаем Santo Stefano Bianco, добавляем индиан тоник, перемешиваем коктейль, добавляем дольку лайма'
  },
  {
    id: 3,
    image: recipe3,
    shortName: 'Rosato & Tonic',
    name: 'Santo Stefano Rosato & Tonic',
    alcohol: '13.5%',
    complexity: 'Простой',
    time: '5 минут',
    ingredients: 'Лёд (кубики) – 200 гр.<br />Santo Stefano Rosato – 100 мл.<br />Indian Tonic – 100 мл.<br />Веточка мяты',
    descr: 'Берем бокал, наполняем бокал льдом, наливаем Santo Stefano Rosato, добавляем индиан тоник, перемешиваем коктейль, добавляем веточку мяты'
  }
];

export default recipes;