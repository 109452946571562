import '../../css/carousel-merch.css';
import merch from '../data/merch';
import State from '../data/State';
import User from '../data/User';
import { screen } from '../types/enums';

const START_POSITION = -58;
const INDENT = 65;
const DELAY = 700;
const SWIPE_VALUE = 10;

export default () => {
  const first = START_POSITION;
  const last = START_POSITION + (merch.length - 1) * INDENT;
  let disable: boolean = false;
  let touchPosition: number = null;

  const toLeft = () => {
    if (disable) return;
    disable = true;
    merch.map(item => {
      const card: HTMLDivElement = document.querySelector('#card-merch-' + item.id);
      const left = Number(card.style.left.slice(0, -2));

      if (left !== first) {
        card.style.transition = 'all ' + DELAY + 'ms ease-in-out';
        card.style.left = (left - INDENT) + 'vw';
      } else {
        card.style.left = last + 'vw';
      }
      const scale = left === START_POSITION + INDENT * 2 ? '1' : '0.8';
      card.style.scale = scale;
    });
    update();
  }

  const toRight = () => {
    if (disable) return;
    disable = true;
    merch.map(item => {
      const card: HTMLDivElement = document.querySelector('#card-merch-' + item.id);
      const left = Number(card.style.left.slice(0, -2));

      if (left !== last) {
        card.style.transition = 'all ' + DELAY + 'ms ease-in-out';
        card.style.left = (left + INDENT) + 'vw';
      } else {
        card.style.left = first + 'vw';
      }
      const scale = left === START_POSITION ? '1' : '0.8';
      card.style.scale = scale;
    });
    update();
  }

  const update = () => {
    setTimeout(() => {
      merch.map(item => {
        const card: HTMLDivElement = document.querySelector('#card-merch-' + item.id);
        card && (card.style.transition = 'none');
      });
      disable = false;
    }, DELAY);
  }

  const carousel = merch.map((item, index) => {
    const position = START_POSITION + index * INDENT;
    const scale = position === START_POSITION + INDENT ? 1 : .8;
    const discount = item.discount ? item.discount.toString() + ' Б' : null;
    return (
      <div
        key={ item.id }
        id={'card-merch-' + item.id}
        className='carousel-merch-card'
        style={{
          left: position.toString() + 'vw',
          scale: scale.toString()
        }}
        onClick={() => {
          const div = document.querySelector('#card-merch-' + item.id) as HTMLDivElement
          if (div?.style?.scale === '1' && User.isRegistered()) {
            State.setActiveMerch(item.id);
            State.setScreen(screen.MERCH);
          }
        }}
      >
        <div className='carousel-merch-card-content'>
          <div className='carousel-merch-card-name'>{ item.name }</div>
          <div className='carousel-merch-card-price'>{ item.price } Б</div>
          <div className='carousel-merch-card-discount'>{ discount }</div>
        </div>
        <div className='carousel-merch-card-image' style={{ backgroundImage: 'url( ' + item.icon + ')' }}></div>
      </div>
    );
  });

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    touchPosition = e.touches[0].clientX;
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    const touchDown = touchPosition
    if (touchDown === null) return;
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > SWIPE_VALUE) toLeft();
    if (diff < -SWIPE_VALUE) toRight();
    touchPosition = null;
  }

  return (
    <div className='carousel-merchs-block' onTouchStart={ handleTouchStart } onTouchMove={ handleTouchMove }>
      { carousel }
      <div className='carousel-merch-button-left' onClick={ toRight }></div>
      <div className='carousel-merch-button-right' onClick={ toLeft }></div>
    </div>
  );
}