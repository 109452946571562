import '../../css/order.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import axios from 'axios';
import HeaderLogo from '../components/HeaderLogo';
import BackButton from '../components/BackButton';
import Tabbar from '../components/Tabbar';
import State from '../data/State';
import User from '../data/User';
import Profile from '../data/Profile';
import merch from '../data/merch';

enum page {
  FIRST,
  SECOND
}

export default observer(() => {
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(page.FIRST);
  const [name, setName] = useState('');
  const [city, setCity] = useState(User.getCity());
  const [address, setAddress] = useState('');
  const button = name !== '' && city !== '' && address !== '';

  const firstPage = (
    <div className='order-data-block'>
      <div className='order-input-block'>
        <div className='order-input-label'>Ф.И.О. получателя</div>
        <input className='order-input' value={ name } onChange={e => {
          setName(e.target.value);
        }} placeholder='Иванов Иван Иванович' type='text' />
      </div>
      <div className='order-input-block'>
        <div className='order-input-label'>Город</div>
        <input className='order-input' value={ city } onChange={e => {
          setCity(e.target.value);
        }} placeholder='Москва' type='text' />
      </div>
      <div className='order-input-block'>
        <div className='order-input-label'>Адрес</div>
        <input className='order-input' value={ address } onChange={e => {
          setAddress(e.target.value);
        }} placeholder='3-я ул.Строителей, д.25, кв.12' type='text' />
      </div>
      <div className='order-button-block'>
        <div className='gold-button' style={{ opacity: button ? 1 : .5 }} onClick={() => {
          if (!button) return;
          setStep(page.SECOND);
        }}>Продолжить</div>
      </div>
    </div>
  );

  const items = Profile.getOrder().map(item => {
    const icon = merch.find(data => data.id === item.id).icon;
    return (
      <div key={ item.id } className='order-item' style={{ backgroundImage: 'url(' + icon + ')' }}></div>
    )
  });

  const secondPage = (
    <>
      <div className='order-label'>Адрес доставки</div>
      <div className='order-info'>{ city + ', ' + address }</div>
      <div className='order-label'>Состав заказа</div>
      <div className='order-merch-block'>{ items }</div>
      <div className='order-button-block'>
        <div className='gold-button' onClick={() => {
          sendOrder();
          setShowModal(true);
        }}>Оформить</div>
      </div>
    </>
  );

  const modal = (
    <div className='order-modal-block'>
      <div className='order-modal'>
        <div className='order-modal-header'>Заказ принят!</div>
        <div className='order-modal-text'>В ближайшее время мы свяжемся с тобой для уточнения деталей.</div>
        <div className='order-modal-button' onClick={() => {
          State.setScreen(screen.ORDERS);
          Profile.removeCartAll();
          Profile.setOrder([]);
        }}>Мои заказы</div>
      </div>
    </div>
  );

  const sendOrder = () => {
    const order: Iorder[] = [];
    Profile.getOrder().map(item => {
      const data = merch.find(data => data.id === item.id);
      order.push({
        id: item.id,
        name: data.name,
        count: item.count,
        size: item.size,
        price: data.price
      });
    });
    axios.post(process.env.REACT_APP_API + '/sendOrder', {
      init_data: User.getInitData(),
      id: User.getID(),
      order: order,
      name: name,
      city: city,
      address: address
    });
  }
  return (
    <div className='order-block'>
      <HeaderLogo />
      <BackButton callback={() => State.toBackScreen()} />
      <div className='order-header-indent'></div>
      <div className='order-header'>ОФОРМЛЕНИЕ ЗАКАЗА</div>
      <div className='order-label'>Получатель</div>
      { step === page.SECOND && <div className='order-info'>{ name }</div> }
      <div className='order-info'>{ User.getPhone() }</div>
      <div className='order-info'>{ User.getMail() }</div>
      { step === page.FIRST ? firstPage : secondPage }
      <div className='order-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
      { showModal && modal }
    </div>
  );
});