import '../../css/promo.css';
import { observer } from 'mobx-react-lite';
import State from '../data/State';
import { screen } from '../types/enums';
import BackButton from '../components/BackButton';

export default observer(() => {
  return (
    <div className='promo-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <div className='promo-header-block'>
        <div className='promo-header'><span>ВЫИГРАЙ</span><br />IPHONE 15</div>
      </div>
      <div className='promo-list-block'>
        <div className='promo-list-item-block'>
          <div className='promo-list-icon promo-icon-1'></div>
          <div className='promo-list-text'>Покупай Santo Stefano Rosso<br />Zero или Santo Stefano Bianco<br />Zero в METRO</div>
        </div>
        <div className='promo-list-item-block'>
          <div className='promo-list-icon promo-icon-2'></div>
          <div className='promo-list-text'>Сканируй чек в этом<br />приложении*</div>
        </div>
        <div className='promo-list-item-block'>
          <div className='promo-list-icon promo-icon-3'></div>
          <div className='promo-list-text'>Жди оповещение<br />от бота!</div>
        </div>
      </div>
      <div className='promo-scan-block'>
        <div className='promo-scan-text'>Еженедельно будем дарить<br />по пять iPhone 15 256GB<br />за покупки от <span>двух</span> бутылок<br />Santo Stefano Zero</div>
        <div className='promo-scan-button-block'>
          <div className='promo-scan-button' onClick={() => State.setScreen(screen.SCAN_METRO)}></div>
        </div>
      </div>
      <div className='promo-copyright-block'>
        <div className='promo-copyright-1'>*принимаются чеки с наличием двух или более<br />позиций Santo Stefano Zero в чеке</div>
        <div className='promo-copyright-2'>Подробные условия акция на сайте:<br /><a href='https://santo-stefano.ru/rules'>santo-stefano.ru/rules</a><br /><br />В акции могут принимать участие только лица, достигшие<br />возраста 18 лет. Организатор акции – ООО «ТД<br />Ренессанс» ОГРН 12345678. © Все права защищены.</div>
        <div className='promo-copyright-button-block'>
          <div className='blue-button' onClick={() => State.setScreen(screen.MAIN)}>Назад</div>
        </div>
      </div>
    </div>
  );
});