import '../../css/club.css';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import Status from '../components/Status';
import State from '../data/State';
import User from '../data/User';
import Utils from '../data/Utils';
import { screen, status } from '../types/enums';

export default observer(() => {
  const [showModal, setModal] = useState(false);

  const modal = (
    <div className='club-modal-block'>
      <div className='club-modal'>
        <div className='club-modal-header'>Скопировано в буфер обмена</div>
        <div className='club-modal-buttons'>
          <div className='club-modal-button-gold' onClick={() => {
            setModal(false);
          }}>OK</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className='club-block'>
      <HeaderLogo />
      <div className='club-avatar' style={{ backgroundImage: 'url(' + User.getAvatar() + ')' }}></div>
      <div className='club-header-scores'>{ Utils.formatSum(User.getScores()) } Б</div>
      <div className='club-header-indent'></div>
      {/* <Status status={ status.GOLD } /> */}
      <div className='club-referral-header'>Реферальная программа</div>
      <div className='club-referral-santo'>Santo stefano</div>
      <div className='club-referral-descr'>Приглашайте друзей и зарабатывайте<br />еще больше баллов</div>
      <div className='club-referral-button' onClick={() => {
        Utils.copy(process.env.REACT_APP_BOT + '?start=' + User.getID());
        setModal(true);
      }}>ПОДЕЛИТЬСЯ С ДРУЗЬЯМИ</div>
      <div className='club-scan-header'>Сканируйте<br /><span>чеки</span></div>
      <div className='club-scan-descr'>Покупайте Santo Stefano<br />и зарабатывайте баллы!</div>
      <div className='club-scan-button-block'>
        <div className='club-scan-button' onClick={() => State.setScreen(User.isRegistered() ? screen.SCAN_CHOOSE : screen.REG)}></div>
      </div>
      <div className='club-menu-button' onClick={() => State.setScreen(User.isRegistered() ? screen.DATA : screen.REG) }>Данные</div>
      <div className='club-menu-button' onClick={() => State.setScreen(screen.MOVEMENTS)}>История начислений</div>
      <div className='club-menu-button' onClick={() => State.setScreen(screen.ORDERS)}>Мои заказы</div>
      <div className='club-menu-button' onClick={() => State.setScreen(screen.FAVORITES)}>Избранное</div>
      <div className='club-menu-button' onClick={() => State.setScreen(screen.RULES)}>Правила</div>
      <div className='club-menu-button' onClick={() => State.setScreen(screen.FAQ)}>FAQ</div>
      <div className='club-menu-button' onClick={() => State.setScreen(screen.SUPPORT)}>Связаться с поддержкой</div>
      <div className='club-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
      { showModal && modal }
    </div>
  );
});