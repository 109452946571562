import { productFilter } from '../types/enums';
import product1 from '../../assets/media/products/product-1.png';
import product2 from '../../assets/media/products/product-2.png';
import product3 from '../../assets/media/products/product-3.png';
import product4 from '../../assets/media/products/product-4.png';
import product5 from '../../assets/media/products/product-5.png';
import product6 from '../../assets/media/products/product-6.png';
import product7 from '../../assets/media/products/product-7.png';
import product8 from '../../assets/media/products/product-8.png';
import product9 from '../../assets/media/products/product-9.png';
import product10 from '../../assets/media/products/product-10.png';
import product11 from '../../assets/media/products/product-11.png';
import product12 from '../../assets/media/products/product-12.png';
import product13 from '../../assets/media/products/product-13.png';
import product14 from '../../assets/media/products/product-14.png';
import product15 from '../../assets/media/products/product-15.png';
import product16 from '../../assets/media/products/product-16.png';
import product17 from '../../assets/media/products/product-17.png';
import product18 from '../../assets/media/products/product-18.png';

const products: Iproduct[] = [
  {
    id: 13,
    image: product13,
    name: 'Brut',
    label: 'Игристое',
    descr: 'Натуральный аромат винограда белых сортов',
    bigDescr: 'Santo Stefano Brut – яркий и аутентичный Игристый Брют от легендарного Санто Стефано. Легкое и свежее вино, которым можно наслаждаться как самостоятельно, так и в сочетании с различными блюдами. Игристое вино произведено по технологии Шармá-Мартинотти.',
    color: 'От золотистого до пшеничного',
    taste: 'Мягкий вкус с освежающим всплеском пузырьков',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.SPARKLING
  },
  {
    id: 14,
    image: product14,
    name: 'Brut Rose',
    label: 'Игристое',
    descr: 'Натуральный аромат винограда белых и красных сортов',
    bigDescr: 'Santo Stefano Brut Rose – яркий и аутентичный Игристый Брют от легендарного Санто Стефано. Легкое и свежее вино, которым можно наслаждаться как самостоятельно, так и в сочетании с различными блюдами. Игристое вино произведено по технологии Шармá-Мартинотти.',
    color: 'От глубокого розового до бледно-рубинового',
    taste: 'Мягкий вкус с освежающим всплеском пузырьков',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.SPARKLING
  },
  {
    id: 6,
    image: product6,
    name: 'Bianco Amabile',
    label: 'Игристое',
    descr: 'Свежий и насыщенный, с медово-мускатными оттенками',
    bigDescr: 'Santo Stefano Bianco Amabile - напиток с утонченным мускатным ароматом и нотками меда. Отлично подходит как в качестве аперитива, так и сопровождения твердых и полутвердых сыров, суши, белого  винограда. Подавать охлажденным до 8-10С в бокалах "Тюльпанах" или высоких узких фужерах.',
    color: 'От нежно-соломенного до золотистого',
    taste: 'Яркий вкус с умеренной сладостью и долгим послевкусием',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.SPARKLING
  },
  {
    id: 7,
    image: product7,
    name: 'Rose Amabile',
    label: 'Игристое',
    descr: 'Свежий и элегантный с тонами земляники, красных ягод и цвето',
    bigDescr: 'Santo Stefano Rose Amabile – напиток обладает приятным нежным ароматом земляники, красных ягод и цветов. Идеально сочетается с фруктовым и сырным канапе, хрустящими хлебцами, лимонным пирогом, спелой клубникой. Подавать охлажденным до 8-10С в бокалах "Тюльпанах" или высоких узких фужерах.',
    color: 'От светло-розового до розового',
    taste: 'Мягкий и утонченный, с нежным сбалансированным послевкусием',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.SPARKLING
  },
  {
    id: 8,
    image: product8,
    name: 'Rosso Amabile',
    label: 'Игристое',
    descr: 'Утонченное сочетание клубники и ванили',
    bigDescr: 'Santo Stefano Rosso Amabile - напиток с утонченными нотками клубники и ванили. Напиток идеален  в качестве дижестива и аперитива. Отличное сопровождение к хрустящим брускеттам с клубникой и сыром фета, блинчиками. Подавать охлажденным до 8-10С в бокалах "Тюльпанах" или высоких узких фужерах.',
    color: 'Нежно-рубиновый',
    taste: 'Полный и насыщенный вкус с ягодными нотами',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.SPARKLING
  },
  {
    id: 9,
    image: product9,
    name: 'Matrimonio Siciliano',
    label: 'Игристое',
    descr: 'Многогранный с нотками спелого сицилийского  персика и абрикоса',
    bigDescr: 'Santo Stefano Matrimonio Siciliano (Сицилийская Свадьба) – это утонченный аромат с нотками сицилийского спелого персика и абрикоса. Идеально сочетается с фруктовым салатом, сырной фокаччей, сухариками «Бискотти».',
    color: 'От светло-соломенного до золотистого',
    taste: 'Нежный и сбалансированный, с отличным сочным послевкусием',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.SPARKLING
  },
  {
    id: 10,
    image: product10,
    name: 'Vacanze Romane',
    label: 'Игристое',
    descr: 'Утонченный с нотками ванили',
    bigDescr: 'Santo Stefano Vacanze Romane (Римские Каникулы) – свежий, сбалансированный напиток, с приятным, нежным ванильным послевкусием. Освежающий вкус итальянского пломбира делает напиток прекрасным компаньоном для фруктов, хлебных палочек с кунжутом, ванильного бисквита.',
    color: 'От светло-соломенного до золотистого',
    taste: 'Оригинальный вкус джелато с долгим послевкусием',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.SPARKLING
  },
  {
    id: 12,
    image: product12,
    name: 'Bianco Semisecco',
    label: 'Игристое',
    descr: 'Насыщенный цветочно-фруктовый',
    bigDescr: 'Santo Stefano Bianco Semisecco - напиток с узнаваемым медово-цветочным ароматом белого муската, так любимого почитателями винограда из местечка Асти. С напитком прекрасно сочетаются твердые и полутвердые сыры, тонкая Гавайская пицца, фруктово-сырная тарелка.',
    color: 'От светло-соломенного до золотистого',
    taste: 'Нежный, сбалансированный с мягким послевкусием',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.SPARKLING
  },
  {
    id: 11,
    image: product11,
    name: 'Aperini Spritz',
    label: 'Игристое',
    descr: 'Насыщенные ноты апельсиновой цедры с благородными травянисто-древесными оттенками',
    bigDescr: 'Santo Stefano Aperini Spritz (Аперини Шприц) – игристый, оранжевый напиток со вкусом сладких сицилийских апельсинов и пикантной горчинкой хинного дерева. Обладает пьянящим ароматом апельсиновой цедры с благородными травянисто-древесными нотами. Идеально сочетается с итальянскими снеками: брускетты с разнообразными топпингами, чесночные фокаччи, тонкие слайсы вяленого мяса, фруктовые пиццы.',
    color: 'Яркий красно-оранжевый',
    taste: 'Цитрусовый и темпераментный, с легкой пикантной горчинкой',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.SPARKLING
  },
  {
    id: 4,
    image: product4,
    name: 'Bianco Zero',
    label: 'Безалкогольное',
    descr: 'Натуральный аромат винограда белых сортов',
    bigDescr: 'Santo Stefano Zero – безалкогольный напиток с гармоничными, свежими, мускатно-цветочными оттенками. С добавлением натурального виноградного сусла и натурального подсластителя стевии.',
    color: 'От золотистого до пшеничного',
    taste: 'Гармоничный вкус, со свежими, мускатно-цветочными оттенками',
    alcohol: '0 градусов',
    type: 'Полусладкое',
    tag: productFilter.NON_ALCOHOLIC
  },
  {
    id: 5,
    image: product5,
    name: 'Rosso Zero',
    label: 'Безалкогольное',
    descr: 'Натуральный аромат винограда красных сортов',
    bigDescr: 'Santo Stefano Zero – безалкогольный напиток с гармоничными, свежими оттенками. С добавлением натурального виноградного сусла и натурального подсластителя стевии.',
    color: 'От глубокого розового до бледно-рубинового',
    taste: 'Мягкий вкус со свежими оттенками красных фруктов и ягод',
    alcohol: '0 градусов',
    type: 'Полусладкое',
    tag: productFilter.NON_ALCOHOLIC
  },
  {
    id: 18,
    image: product18,
    name: 'Brut Rose Zero',
    label: 'Безалкогольное',
    descr: 'Нежный аромат с тонкими цветочными и ягодными оттенками',
    bigDescr: 'Santo Stefano Brut Rose Zero – легкое игристое безалкогольное вино розового цвета с благородным лососевым оттенком. Элегантный перляж и красивая воздушная пена создают неповторимую праздничную атмосферу. В аромате вино раскрывается нотами красных ягод и лепестков розы, подчеркнутыми игривой леденцовой сладостью.<br /><br />Это вино идеально подходит в качестве аперитива, а также как дополнение к легким закускам, салатам и морепродуктам. Оно станет отличным выбором для тех, кто ценит качество и изящество без алкоголя. Подавать охлажденным до 8-10°C.',
    color: 'Розового цвета с благородным лососевым оттенком',
    taste: 'Вкус чистый, хрустящий и освежающий, с пикантным цитрусовым и минерально-ягодным послевкусием. ',
    alcohol: '0 градусов',
    type: 'Полусладкое',
    tag: productFilter.NON_ALCOHOLIC
  },
  {
    id: 15,
    image: product15,
    name: 'Bianco Zero<br />(0.25 L)',
    label: 'Безалкогольное',
    descr: 'Натуральный аромат винограда белых сортов',
    bigDescr: 'Santo Stefano Bianco Zero – безалкогольный напиток с гармоничными, свежими, мускатно-цветочными оттенками. С добавлением натурального виноградного сусла и натурального подсластителя стевии.',
    color: 'От золотистого до пшеничного',
    taste: 'Гармоничный вкус, со свежими, мускатно-цветочными оттенками',
    alcohol: '0 градусов',
    type: 'Полусладкое',
    tag: productFilter.NON_ALCOHOLIC
  },
  {
    id: 16,
    image: product16,
    name: 'Rosso Zero<br />(0.25 L)',
    label: 'Безалкогольное',
    descr: 'Натуральный аромат винограда красных сортов',
    bigDescr: 'Santo Stefano Rosso Zero – безалкогольный напиток с гармоничными, свежими оттенками. С добавлением натурального виноградного сусла и натурального подсластителя стевии.',
    color: 'От глубокого розового до бледно-рубинового',
    taste: 'Мягкий вкус со свежими оттенками красных фруктов и ягод',
    alcohol: '0 градусов',
    type: 'Полусладкое',
    tag: productFilter.NON_ALCOHOLIC
  },
  {
    id: 17,
    image: product17,
    name: 'Brut Rose Zero<br />(0.25 L)',
    label: 'Безалкогольное',
    descr: 'Нежный аромат с тонкими цветочными и ягодными оттенками',
    bigDescr: 'Santo Stefano Brut Rose Zero – легкое игристое безалкогольное вино розового цвета с благородным лососевым оттенком. Элегантный перляж и красивая воздушная пена создают неповторимую праздничную атмосферу. В аромате вино раскрывается нотами красных ягод и лепестков розы, подчеркнутыми игривой леденцовой сладостью.<br /><br />Это вино идеально подходит в качестве аперитива, а также как дополнение к легким закускам, салатам и морепродуктам. Оно станет отличным выбором для тех, кто ценит качество и изящество без алкоголя. Подавать охлажденным до 8-10°C.',
    color: 'Розового цвета с благородным лососевым оттенком',
    taste: 'Вкус чистый, хрустящий и освежающий, с пикантным цитрусовым и минерально-ягодным послевкусием. ',
    alcohol: '0 градусов',
    type: 'Полусладкое',
    tag: productFilter.NON_ALCOHOLIC
  },
  {
    id: 1,
    image: product1,
    name: 'Bianco',
    label: 'Вермут',
    descr: 'Интенсивный и многогранный аромат, наполненный оттенками трав и специй',
    bigDescr: 'Знаменитый итальянский аперитив от легендарного Santo Stefano.',
    color: 'От светло-соломенного до золотистого',
    taste: 'Живой, гармоничный и пряный, с деликатной сладостью и тонкой горчинкой, нотками полыни в освежающем послевкусии',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.VERMOUTH
  },
  {
    id: 2,
    image: product2,
    name: 'Aperini',
    label: 'Вермут',
    descr: 'Сочные тона цедры сицилийских апельсинов с интенсивными травянисто-древесными нотами',
    bigDescr: 'Santo Stefano Aperini – культовый цитрусовый аперитив со вкусом сицилийских красных апельсинов.',
    color: 'Яркий красно-оранжевый',
    taste: 'Насыщенный и сбалансированный, с нотами сладких красных апельсинов и терпкой горчинкой хинного дерева и полыни',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.VERMOUTH
  },
  {
    id: 3,
    image: product3,
    name: 'Rosato',
    label: 'Вермут',
    descr: 'Элегантный и насыщенный с оттенками красных ягод',
    bigDescr: 'Santo Stefano Rosato – яркий ягодный аперитив с ароматом земляники и натуральным экстрактом полыни Artemisia.',
    color: 'Розовый',
    taste: 'Сбалансированный, освежающий, с приятной сладостью и пикантной горчинкой',
    alcohol: '8 градусов',
    type: 'Полусладкое',
    tag: productFilter.VERMOUTH
  }
];

export default products;