import '../../css/contest.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import State from '../data/State';
import User from '../data/User';
import { screen } from '../types/enums';

export default observer(() => {
  useEffect(() => {
    getTimer();
    setInterval(() => {
      getTimer();
    }, 1000 * 60);
  }, []);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const getTimer = () => {
    axios.post(process.env.REACT_APP_API + '/getContestTime').then(res => {
      setDays(res.data.data.days);
      setHours(res.data.data.hours);
      setMinutes(res.data.data.minutes);
    }).catch(() => {});
  }

  const scan = () => {
    User.isRegistered() ? State.setScreen(screen.SCAN_CHOOSE) : State.setScreen(screen.REG);
  }
  return (
    <div className='contest-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <div className='contest-header'>
        <div className='contest-header-label'></div>
        <div className='contest-time-label'>
          С 15 ноября 2024 по 15 января 2025<br />
          <span>подарки ждут именно тебя!</span>
        </div>
        <div className='contest-gifts'></div>
      </div>
      <div className='contest-stock'></div>
      <div className='contest-partners'>
        <div className='contest-scan-button' onClick={ scan }>Зарегистрировать чек</div>
      </div>
      <div className='contest-timer'>
        <div className='contest-timer-block'>
          <div className='contest-timer-part'>
            <div className='contest-timer-label'>Дни</div>
            <div className='contest-timer-board-block'>
              <div className='contest-timer-board'>{ days > 9 ? days.toString().slice(0, 1) : 0 }</div>
              <div className='contest-timer-board'>{ days.toString().slice(-1) }</div>
            </div>
          </div>
          <div className='contest-timer-part-between'></div>
          <div className='contest-timer-part'>
            <div className='contest-timer-label'>Часы</div>
            <div className='contest-timer-board-block'>
              <div className='contest-timer-board'>{ hours > 9 ? hours.toString().slice(0, 1) : 0 }</div>
              <div className='contest-timer-board'>{ hours.toString().slice(-1) }</div>
            </div>
          </div>
          <div className='contest-timer-part-between'>
            <div className='contest-timer-part-dots'>:</div>
          </div>
          <div className='contest-timer-part'>
            <div className='contest-timer-label'>Минуты</div>
            <div className='contest-timer-board-block'>
              <div className='contest-timer-board'>{ minutes > 9 ? minutes.toString().slice(0, 1) : 0 }</div>
              <div className='contest-timer-board'>{ minutes.toString().slice(-1) }</div>
            </div>
          </div>
        </div>
      </div>
      <div className='contest-merch'>
        <div className='contest-merch-button' onClick={() => State.setScreen(screen.STORE)}>Заказать мерч</div>
      </div>
      <div className='contest-faq'></div>
    </div>
  );
});