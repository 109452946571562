import '../../css/product-card.css';
import { observer } from 'mobx-react-lite';
import Profile from '../data/Profile';

export default observer(({ data, callback }: { data: Iproduct, callback: Function }) => {
  const favorite = Profile.getProductFavorites().find(item => item === data.id) ? 'enable' : 'disable';
  return (
    <div className='product-card' onClick={() => callback()}>
      <div className='product-card-image' style={{ backgroundImage: 'url(' + data.image + ')' }}></div>
      <div className='product-card-info-block'>
        <div
          className={'product-favorit-icon ' + favorite}
          onClick={e => {
            e.stopPropagation();
            favorite === 'enable' ? Profile.removeProductFavorite(data.id) : Profile.addProductFavorite(data.id);
          }}
        ></div>
        <div className='product-card-name' dangerouslySetInnerHTML={{ __html: data.name }}></div>
        <div className='product-card-descr'>{ data.taste }</div>
      </div>
    </div>
  );
});