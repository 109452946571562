import '../../css/favorites.css';
import { useState } from 'react';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import MerchCard from '../components/MerchCard';
import ProductCard from '../components/ProductCard';
import Profile from '../data/Profile';
import State from '../data/State';
import products from '../data/products';
import merch from '../data/merch';
import User from '../data/User';
import { screen } from '../types/enums';

enum switchFilter {
  ALL,
  PRODUCTS,
  MERCH
}

export default () => {
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState<switchFilter>(switchFilter.ALL);
  const data: IfavoritesData[] = [];

  (filter === switchFilter.ALL || filter === switchFilter.PRODUCTS) && products.map(item => {
    Profile.getProductFavorites().find(data => data === item.id) && data.push({
      type: switchFilter.PRODUCTS,
      data: item
    });
  });
  (filter === switchFilter.ALL || filter === switchFilter.MERCH) && merch.map(item => {
    Profile.getMerchFavorites().find(data => data === item.id) && data.push({
      type: switchFilter.MERCH,
      data: item
    });
  });
  data.sort((a, b) => a.data.id - b.data.id);

  const left = data.map((item, index) => {
    if (index % 2 !== 0) return;
    return (
      <div key={ item.data.id }>
        { item.type === switchFilter.MERCH && <MerchCard data={ item.data as Imerch } callback={() => {
          if (User.isRegistered()) {
            State.setActiveMerch(item.data.id);
            State.setScreen(screen.MERCH);
          } else {
            setShowModal(true);
          }
        }} /> }
        { item.type === switchFilter.PRODUCTS && <ProductCard data={ item.data as Iproduct } callback={() => {
          State.setActiveProduct(item.data.id);
          State.setScreen(screen.PRODUCT);
        }} /> }
      </div>
    );
  });

  const right = data.map((item, index) => {
    if (index % 2 === 0) return;
    return (
      <div key={ item.data.id }>
        { index === 1 && <div className='favorites-card-indent'></div> }
        { item.type === switchFilter.MERCH && <MerchCard data={ item.data as Imerch } callback={() => {
          if (User.isRegistered()) {
            State.setActiveMerch(item.data.id);
            State.setScreen(screen.MERCH);
          } else {
            setShowModal(true);
          }
        }} /> }
        { item.type === switchFilter.PRODUCTS && <ProductCard data={ item.data as Iproduct } callback={() => {
          State.setActiveProduct(item.data.id);
          State.setScreen(screen.PRODUCT);
        }} /> }
      </div>
    );
  });

  const modal = (
    <div className='store-modal-block'>
      <div className='store-modal'>
        <div className='store-modal-header'>Зарегистрируйся</div>
        <div className='store-modal-descr'>Для использования всех функциональных<br />возможностей бота необходимо<br />зарегистрироваться</div>
        <div className='store-modal-buttons-block'>
          <div className='store-modal-button' onClick={() => State.setScreen(screen.REG)}>Зарегистрироваться</div>
          <div onClick={() => setShowModal(false)}>Отменить</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className='favorites-block'>
      <HeaderLogo />
      <BackButton callback={() => State.toBackScreen()} />
      <div className='favorites-info-block'>
        <div className='favorites-title'>ИЗБРАННОЕ</div>
      </div>
      <div className='favorites-switch-content'>
        <div className='favorites-switch-block'>
          <div
            className={'favorites-switch-button-' + (filter === switchFilter.ALL ? 'enable' : 'disable')}
            onClick={() => setFilter(switchFilter.ALL)}
          ><div>Все</div></div>
          <div
            className={'favorites-switch-button-' + (filter === switchFilter.PRODUCTS ? 'enable' : 'disable')}
            onClick={() => setFilter(switchFilter.PRODUCTS)}
          ><div>Напитки</div></div>
          <div
            className={'favorites-switch-button-' + (filter === switchFilter.MERCH ? 'enable' : 'disable')}
            onClick={() => setFilter(switchFilter.MERCH)}
          ><div>Мерч</div></div>
        </div>
      </div>
      <div className='favorites-card-block'>
        <div className='favorites-row'>{ left }</div>
        <div className='favorites-row'>{ right }</div>
      </div>
      <div className='favorites-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
      { showModal && modal }
    </div>
  );
}