import '../../css/support.css';
import axios from 'axios';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import State from '../data/State';
import User from '../data/User';
import { screen } from '../types/enums';

const topics = [
  {
    id: 1,
    text: 'Проблемы со сканированием'
  },
  {
    id: 2,
    text: 'Проблемы с оформлением заказа'
  },
  {
    id: 3,
    text: 'Проблемы с личными данными'
  },
  {
    id: 4,
    text: 'Проблемы с игрой'
  },
  {
    id: 5,
    text: 'Проблемы с регистрацией'
  },
  {
    id: 6,
    text: 'Проблемы с приложением / чат-ботом'
  },
];

export default observer(() => {
  const [topic, setTopic] = useState<number>(null);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [text, setText] = useState('');
  const [mailError, setMailError] = useState(false);
  const [topicError, setTopicError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disable, setDisable] = useState(false);

  const list = topics.map(data => {
    return (
      <div key={ data.id } onClick={() => {
        setTopic(data.id);
        setOpen(false);
      }}>{ data.text }</div>
    );
  });
  
  const buttonActive = Boolean(name && mail && text);
  const topicName = topic ? topics.find(data => data.id === topic).text : 'Тема';
  const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const sendMessage = () => {
    const testMail = emailRegExp.test(mail);
    !testMail && setMailError(true);
    !topic && setTopicError(true);

    if (testMail && topic) {
      setDisable(true);
      axios.post(process.env.REACT_APP_API + '/sendSupportMessage', {
        init_data: User.getInitData(),
        id: User.getID(),
        topic: topics.find(data => data.id === topic).text,
        name: name,
        mail: mail,
        text: text,
      }).then(res => {
        if (!res.data.error) {
          setShowModal(true);
        } else {
          State.setError(res.data.error_type);
          State.setScreen(screen.ERROR);
        }
      }).catch(e => State.setScreen(screen.ERROR));
    }
  }

  const modal = (
    <div className='support-modal-block'>
      <div className='support-modal'>
        <div className='support-modal-header'>Обращение принято!</div>
        <div className='support-modal-text'>В ближайшее время мы отправим ответ на почту.</div>
        <div className='support-modal-button' onClick={() => State.setScreen(screen.MAIN)}>На главную</div>
      </div>
    </div>
  );

  return (
    <div className='support-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='support-header'>ТЕХ ПОДДЕРЖКА</div>
      <div className='support-data-block'>
        <div className='support-input-block'>
          <div className={'support-topic-field ' + (open ? 'support-acitve-filed ' : '') + (topicError ? 'support-error' : '') } onClick={() => {
            setOpen(!open)
            open && setTopicError(false);
          }}>
            { topicName }
            { open && <div className='support-topic-list'>{ list }</div> }
          </div>
        </div>
        <div className='support-input-block'>
          <div className='support-input-label'>Имя</div>
          <input className='support-input' value={ name } onChange={e => setName(e.target.value)} placeholder='Мария' type='text' />
        </div>
        <div className='support-input-block'>
          <div className='support-input-label'>email</div>
          <input className={'support-input' + (mailError ? ' support-error' : '')} value={ mail } onChange={e => {
            setMailError(false);
            setMail(e.target.value);
          }} placeholder='example@mail.ru' type='text' />
        </div>
        <div className='support-input-block'>
          <div className='support-input-label'>Комментарий</div>
          <textarea
            placeholder='Опишите проблему, с которой вы столкнулись'
            className='support-textarea'
            onChange={e => setText(e.target.value)}
            defaultValue={ text }
          ></textarea>
        </div>
        <div className='support-button-block'>
        <div className='gold-button' style={{ opacity: buttonActive ? 1 : .5 }} onClick={() => {
            if (!buttonActive || disable) return;
            sendMessage();
          }}>Отправить</div>
        </div>
      </div>
      <div className='support-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
      { showModal && modal }
    </div>
  );
});