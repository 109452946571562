import '../../css/scan-metro.css';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import Header from '../components/Header';
import State from '../data/State';

export default observer(() => {
  return (
    <div className='scan-metro-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <Header />
      <div className='scan-metro-header'>ВЫИГРАЙ IPHONE 15</div>
      <div className='scan-metro-descr'>В этом разделе ты можешь отсканировать чеки,<br />принимающие участие в акции «Название» <br />с <i /></div>
      <div className='scan-metro-stock'>В АКЦИИ УЧАСТВУЮТ:</div>
      <div className='scan-metro-bottles'></div>
      <div className='scan-metro-buttons'>
        <div className='blue-button' onClick={() => console.log('scan')}>Сканировать</div>
        <div className='white-button' onClick={() => console.log('hand')}>Ввести вручную</div>
      </div>
    </div>
  );
});