import '../../css/status.css';
import State from '../data/State';
import User from '../data/User';
import { screen, status } from '../types/enums';

type props = {
  status: status
}

export default (props: props) => {
  const name = props.status === status.GOLD ? 'ЗОЛОТОЙ' : props.status === status.SILVER ? 'СЕРЕБРЯНЫЙ' : 'БРОНЗОВЫЙ';
  return (
    <div className={'status-block ' + props.status}>
      <div className='status-header-block'>
        <div className='status-name'>{ name }</div>
        <div className='status-id'>ID { User.getID() }</div>
      </div>
      <div className='status-bottom-block'>
        <div className='status-scores'>
          <div>Баланс:</div>
          <div>{ User.getScores() } б</div>
        </div>
        <div className='status-link' onClick={() => State.setScreen(screen.STATUSES)}>Подробнее о статусе</div>
      </div>
    </div>
  );
}