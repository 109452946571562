import '../../css/merch-card.css';
import { observer } from 'mobx-react-lite';
import { merchFilter } from '../types/enums';
import Profile from '../data/Profile';

export default observer(({ data, callback }: { data: Imerch, callback: Function }) => {
  const tagName = data.tag === merchFilter.EXCLUSIVE ? 'Эксклюзив' : 'Новинка';
  const tag = data.tag === merchFilter.EXCLUSIVE || data.tag === merchFilter.MAIN ? <div className='merch-card-tag'>{ tagName }</div> : null;
  const discount = data.discount ? data.discount.toString() + ' Б' : null;
  const favorite = Profile.getMerchFavorites().find(item => item === data.id) ? 'enable' : 'disable';

  return (
    <div className='merch-card-block'>
      <div
        className='merch-card-image'
        onClick={() => callback()}
        style={{ backgroundImage: 'url(' + data.image + ')' }}
      >
        {/* { tag } */}
        <div
          className={'merch-card-favorit-icon ' + favorite}
          onClick={e => {
            e.stopPropagation();
            favorite === 'enable' ? Profile.removeMerchFavorite(data.id) : Profile.addMerchFavorite(data.id);
          }}
        ></div>
      </div>
      <div className='merch-card-name'>{ data.name.toUpperCase() }</div>
      <div className='merch-card-price'>{ data.price } Б <span>{ discount }</span></div>
    </div>
  );
});