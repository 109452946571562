import { makeAutoObservable } from 'mobx';
import axios from 'axios';
import User from './User';

class Profile {
  constructor() {
    makeAutoObservable(this);
  }

  private _cart: Icart[] = [];
  private _order: Icart[] = [];
  private _merchFavorits: number[] = [];
  private _productsFavorits: number[] = [];
  private _recipesFavorits: number[] = [];

  public getCart(): Icart[] {
    return this._cart;
  }

  public setSavedCart(cart: Icart[]): void {
    this._cart = cart;
  }

  public setCart(cart: Icart): void {
    if (this._cart.find(data => data.id === cart.id)) {
      this._cart = this._cart.filter(data => data.id !== cart.id);
    }
    this._cart.push(cart);
    this._saveCart();
  }

  public removeCart(id: number): void {
    this._cart = this._cart.filter(data => data.id !== id);
    this._saveCart();
  }

  public removeCartAll(): void {
    this._cart = [];
    this._saveCart();
  }

  public setOrder(order: Icart[]): void {
    this._order = order;
  }

  public getOrder(): Icart[] {
    return this._order;
  }

  public setMerchFavorites(data: number[]): void {
    this._merchFavorits = data;
  }
  
  public getMerchFavorites(): number[] {
    return this._merchFavorits;
  }

  public addMerchFavorite(id: number): void {
    const have = this._merchFavorits.find(data => data === id);
    !have && this._merchFavorits.push(id);
    this._saveFavorites();
  }

  public removeMerchFavorite(id: number): void {
    this._merchFavorits = this._merchFavorits.filter(data => data !== id);
    this._saveFavorites();
  }

  public setProductFavorites(data: number[]): void {
    this._productsFavorits = data;
  }
  
  public getProductFavorites(): number[] {
    return this._productsFavorits;
  }

  public addProductFavorite(id: number): void {
    const have = this._productsFavorits.find(data => data === id);
    !have && this._productsFavorits.push(id);
    this._saveFavorites();
  }

  public removeProductFavorite(id: number): void {
    this._productsFavorits = this._productsFavorits.filter(data => data !== id);
    this._saveFavorites();
  }

  public setRecipeFavorites(data: number[]): void {
    this._recipesFavorits = data;
  }
  
  public getRecipeFavorites(): number[] {
    return this._recipesFavorits;
  }

  public addRecipeFavorite(id: number): void {
    const have = this._recipesFavorits.find(data => data === id);
    !have && this._recipesFavorits.push(id);
    this._saveFavorites();
  }

  public removeRecipeFavorite(id: number): void {
    this._recipesFavorits = this._recipesFavorits.filter(data => data !== id);
    this._saveFavorites();
  }

  private _saveFavorites(): void {
    axios.post(process.env.REACT_APP_API + '/saveFavorites', {
      init_data: User.getInitData(),
      id: User.getID(),
      merch: this._merchFavorits,
      products: this._productsFavorits,
      recipes: this._recipesFavorits
    });
  }

  private _saveCart(): void {
    axios.post(process.env.REACT_APP_API + '/saveCart', {
      init_data: User.getInitData(),
      id: User.getID(),
      cart: this._cart
    });
  }
}

export default new Profile();