import '../../css/statuses.css';
import { observer } from 'mobx-react-lite';
import HeaderLogo from '../components/HeaderLogo';
import BackButton from '../components/BackButton';
import Tabbar from '../components/Tabbar';
import Status from '../components/Status';
import State from '../data/State';
import { status } from '../types/enums';

export default observer(() => {
  return (
    <>
      <div className='statuses-block'>
        <HeaderLogo />
        <BackButton callback={() => State.toBackScreen()} />
        <div className='statuses-title-block'>Статусы</div>
        <div className='statuses-content-block'>
          <div className='statuses-descr'>Текст описания статусов, за что и как их выдают Текст описания статусов, за что и как их выдают </div>
          <div className='statuses-status'>
            <Status status={ status.GOLD } />
          </div>
          <div className='statuses-descr'>Текст описания статусов, за что и как их выдают Текст описания статусов, за что и как их выдают </div>
          <div className='statuses-status'>
            <Status status={ status.SILVER } />
          </div>
          <div className='statuses-descr'>Текст описания статусов, за что и как их выдают Текст описания статусов, за что и как их выдают </div>
          <div className='statuses-status'>
            <Status status={ status.BRONZE } />
          </div>
          <div className='statuses-tabbar-indent'></div>
        </div>
      </div>
      <Tabbar opacity={ 1 } />
    </>
  );
});