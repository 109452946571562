import '../../css/scan-manual.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import State from '../data/State';
import Tabbar from '../components/Tabbar';
import ResultScan from '../components/ResultScan';
import Utils from '../data/Utils';
import axios from 'axios';
import User from '../data/User';

enum pages {
  MANUAL,
  RESULT
}

export default observer(() => {
  const [page, setPage] = useState<pages>(pages.MANUAL);
  const [success, setSuccess] = useState(false);
  const [fn, setFn] = useState('');
  const [fd, setFd] = useState('');
  const [fp, setFp] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [sum, setSum] = useState('');
  const [fnError, setFnError] = useState(false);
  const [fdError, setFdError] = useState(false);
  const [fpError, setFpError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const [sumError, setSumError] = useState(false);
  const [waiting, setWaiting] = useState(false);
  
  const fnRegExp = /^\d{16}$/;
  const fdRegExp = /^\d{1,9}$/;
  const fpRegExp = /^\d{10}$/;
  const timeRegExp = /^(?:0?[0-9]|1[0-9]|2[0-3]):(?:[0-5]\d)$/;
  const sumRegExp = /^\d+(?:[.,]\d+)?$/;

  const formatDate = (inputDate: string, inputTime: string): string => {
    const [day, month, year] = inputDate.split('.');
    const [hours, minutes] = inputTime.split(':');
    const formattedDay = day.padStart(2, '0');
    const formattedMonth = month.padStart(2, '0');
    const formattedHours = hours.padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    const formattedDateTime = `${year}${formattedMonth}${formattedDay}T${formattedHours}${formattedMinutes}`;
    return formattedDateTime;
  }

  const scan = () => {
    const testFn = fnRegExp.test(fn);
    const testFd = fdRegExp.test(fd);
    const testFp = fpRegExp.test(fp);
    const testDate = Utils.isValidDate(date);
    const testTime = timeRegExp.test(time);
    const testSum = sumRegExp.test(sum);
    !testFn && setFnError(true);
    !testFd && setFdError(true);
    !testFp && setFpError(true);
    !testDate && setDateError(true);
    !testTime && setTimeError(true);
    !testSum && setSumError(true);

    if (testFn && testFd && testFp && testDate && testTime && testSum) {
      const data = 't=' + formatDate(date, time) + '&s=' + Number(sum).toFixed(2) + '&fn=' + fn + '&i=' + fd + '&fp=' + fp + '&n=1';
      setWaiting(true);
      axios.post(process.env.REACT_APP_API + '/sendBill', {
        init_data: User.getInitData(),
        id: User.getID(),
        data: data
      }).then(res => {
        if (!res.data.error) {
          const scores = User.getScores() + res.data.data.points;
          res.data.data.success && User.setScores(scores);
          setSuccess(res.data.data.success);
          setPage(pages.RESULT);
        } else {
          State.setError(res.data.error_type);
          State.setScreen(screen.ERROR);
        }
      }).catch(e => State.setScreen(screen.ERROR));
    }
  }

  const regBill = Boolean(fn && fd && fp && date && time && sum);
  const buttonName = waiting ? 'Подождите' : 'Зарегистрировать чек';

  if (page === pages.RESULT) {
    return (
      <ResultScan
        success={ success }
        callback={() => {
          State.setScanWaiting(false);
          setWaiting(false);
          setPage(pages.MANUAL);
        }}
      />
    );
  }

  return (
    <div className='scan-manual-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='scan-manual-header'>ДАННЫЕ ЧЕКА</div>
      <div className='scan-manual-descr'>Пожалуйста, введите данные из чека вручную.</div>
      <div className='scan-manual-example-block'>
        <div className='scan-manual-example-image'></div>
        <div className='scan-manual-example-fields'>
          <div>ФН: 99000000000000012</div>
          <div>ФД: 0000000975</div>
          <div>ФП: 1098955298</div>
        </div>
      </div>
      <div className='scan-manual-data-block'>
        <div className='scan-manual-input-block'>
          <div className='scan-manual-input-label'>ФН из чека</div>
          <input className={'scan-manual-input' + (fnError ? ' scan-manual-error' : '')} value={ fn } onChange={e => {
            setFnError(false);
            setFn(e.target.value);
          }} placeholder='99000000000000000012' type='number' />
        </div>
        <div className='scan-manual-input-block'>
          <div className='scan-manual-input-label'>ФД из чека</div>
          <input className={'scan-manual-input' + (fdError ? ' scan-manual-error' : '')} value={ fd } onChange={e => {
            setFdError(false);
            setFd(e.target.value);
          }} placeholder='0000000972' type='text' />
        </div>
        <div className='scan-manual-input-block'>
          <div className='scan-manual-input-label'>ФДП или ФП из чека</div>
          <input className={'scan-manual-input' + (fpError ? ' scan-manual-error' : '')} value={ fp } onChange={e => {
            setFpError(false);
            setFp(e.target.value);
          }} placeholder='1021012408412048' type='text' />
        </div>
        <div className='scan-manual-input-block'>
          <div className='scan-manual-input-label'>Дата покупки</div>
          <input className={'scan-manual-input' + (dateError ? ' scan-manual-error' : '')} value={ date } onChange={e => {
            setDateError(false);
            let text = e.target.value;
            if ((date.length === 1 && text.length === 2) || (date.length === 4 && text.length === 5)) {
              text += '.';
            }
            if (date.length === 2 && text.length === 3) {
              text = text.slice(0, 2) + '.' + text.slice(-1);
            }
            if (date.length === 5 && text.length === 6) {
              text = text.slice(0, 5) + '.' + text.slice(-1);
            }
            if (text.length > 10) {
              text = text.slice(0, 10);
            }
            setDate(text);
          }} placeholder='01.01.2000' type='text' />
        </div>
        <div className='scan-manual-input-block'>
          <div className='scan-manual-input-label'>Время покупки</div>
          <input className={'scan-manual-input' + (timeError ? ' scan-manual-error' : '')} value={ time } onChange={e => {
            setTimeError(false);
            setTime(e.target.value);
          }} placeholder='12:20' type='text' />
        </div>
        <div className='scan-manual-input-block'>
          <div className='scan-manual-input-label'>Сумма чека</div>
          <input className={'scan-manual-input' + (sumError ? ' scan-manual-error' : '')} value={ sum } onChange={e => {
            setSumError(false);
            setSum(e.target.value);
          }} placeholder='380.00' type='number' />
        </div>
      </div>
      <div className='scan-manual-buttons-block'>
        <div className='gold-button' style={{ opacity: regBill && !waiting ? 1 : .5 }} onClick={() => {
          if (!regBill) return;
          if (waiting) return;
          scan();
        }}>{ buttonName }</div>
        <div className='white-button' onClick={() => State.setScreen(screen.SUPPORT)}>Мне нужна помощь</div>
      </div>
      <div className='scan-manual-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
    </div>
  );
});