import '../../css/error.css';
import { observer } from 'mobx-react-lite';
import { errors } from '../types/enums';
import State from '../data/State';

export default observer(() => {
  const text = 
    State.getError() === errors.NOT_FOUND ? 'Ресурс не найден' :
    State.getError() === errors.INIT_DATA ? 'Ошибка пользовательских данных' :
    State.getError() === errors.FILE_UPLOAD ? 'Ошибка загрузки файла' :
    State.getError() === errors.BANNED ? 'Вы заблокированы до выяснения причины' :
    'Неизвестная ошибка';
  return (
    <div className='error-block'>{ text }</div>
  );
});