import '../../css/data.css';
import axios from 'axios';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import State from '../data/State';
import User from '../data/User';
import Utils from '../data/Utils';
import { screen } from '../types/enums';

export default observer(() => {
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState(User.getName());
  const [mail, setMail] = useState(User.getMail());
  const [phone, setPhone] = useState(User.getPhone());
  const [birthday, setBirthday] = useState(User.getBirthday());
  const [city, setCity] = useState(User.getCity());

  const [mailError, setMailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);

  const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegExp = /^\+7\d{10}$/;

  const save = () => {
    const testMail = emailRegExp.test(mail);
    const testPhone = phoneRegExp.test(phone);
    const testBirthday = Utils.isValidDate(birthday);
    !testMail && setMailError(true);
    !testPhone && setPhoneError(true);
    !testBirthday && setBirthdayError(true);

    if (testMail && testPhone && testBirthday) {
      axios.post(process.env.REACT_APP_API + '/sendRegData', {
        init_data: User.getInitData(),
        id: User.getID(),
        name: name,
        mail: mail,
        phone: phone,
        birthday: birthday,
        city: city
      }).then(res => {
        if (!res.data.error) {
          User.setName(name);
          User.setMail(mail);
          User.setPhone(phone);
          User.setBirthday(birthday);
          User.setCity(city);
          setDisabled(true);
        } else {
          State.setError(res.data.error_type);
          State.setScreen(screen.ERROR);
        }
      }).catch(e => State.setScreen(screen.ERROR));
    }
  }

  const setFormatPhone = (phone: string) => {
    if (phone.length < 3) {

      if (phone !== '+7' && phone !== '+' && phone !== '') {
        phone = '+7';
      }
    } else {

      if (phone[0] !== '+') {
        phone = '+' + phone;
      }

      if (phone[1] !== '7') {
        phone = phone[0] + '7' + phone.slice(2);
      }
      phone = '+7' + phone.slice(2).replace(/[^0-9]/g, '');

      if (phone.length > 12) {
        phone = phone.slice(0, 12);
      }
    }
    setPhone(phone);
  }

  const saveActive = Boolean(name && mail && phone && birthday && city);

  return (
    <div className='data-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='data-header'>
        ДАННЫЕ
        { disabled && <div className='data-edit' onClick={() => setDisabled(false) } /> }
      </div>
      <div className='data-content-block'>
        <div className='data-input-block'>
          <div className='data-input-label'>Имя</div>
          <input disabled={ disabled } className='data-input' value={ name } onChange={e => setName(e.target.value)} placeholder='Мария' type='text' />
        </div>
        <div className='data-input-block'>
          <div className='data-input-label'>email</div>
          <input disabled={ disabled } className={'data-input' + (mailError ? ' data-error' : '')} value={ mail } onChange={e => {
            setMailError(false);
            setMail(e.target.value);
          }} placeholder='example@mail.ru' type='text' />
        </div>
        <div className='data-input-block'>
          <div className='data-input-label'>Телефон</div>
          <input disabled={ disabled } className={'data-input' + (phoneError ? ' data-error' : '')} value={ phone } onChange={e => {
            setPhoneError(false);
            setFormatPhone(e.target.value);
          }} placeholder='+79000000000' type='text' />
        </div>
        <div className='data-input-block'>
          <div className='data-input-label'>Дата рождения</div>
          <input disabled={ disabled } className={'data-input' + (birthdayError ? ' data-error' : '')} value={ birthday } onChange={e => {
            setBirthdayError(false);
            let text = e.target.value;
            if ((birthday.length === 1 && text.length === 2) || (birthday.length === 4 && text.length === 5)) {
              text += '.';
            }
            if (birthday.length === 2 && text.length === 3) {
              text = text.slice(0, 2) + '.' + text.slice(-1);
            }
            if (birthday.length === 5 && text.length === 6) {
              text = text.slice(0, 5) + '.' + text.slice(-1);
            }
            if (text.length > 10) {
              text = text.slice(0, 10);
            }
            setBirthday(text);
          }} placeholder='01.01.2000' type='text' />
        </div>
        <div className='data-input-block'>
          <div className='data-input-label'>Город</div>
          <input disabled={ disabled } className='data-input' value={ city } onChange={e => setCity(e.target.value)} placeholder='Москва' type='text' />
        </div>
        { !disabled && <div className='data-button-block'>
          <div className='gold-button' style={{ opacity: saveActive ? 1 : .5 }} onClick={() => {
            if (!saveActive) return;
            save();
          }}>Сохранить</div>
        </div> }
      </div>
      <div className='data-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
    </div>
  );
});