import '../../css/products.css';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import HeaderLogo from '../components/HeaderLogo';
import BackButton from '../components/BackButton';
import Tabbar from '../components/Tabbar';
import ProductCard from '../components/ProductCard';
import products from '../data/products';
import State from '../data/State';
import { productFilter, screen } from '../types/enums';

export default observer(() => {
  const [filter, setFilter] = useState(State.getProductFilter());
  const data = 
    filter === productFilter.VERMOUTH ? products.filter(data => data.tag === filter) :
    filter === productFilter.NON_ALCOHOLIC ? products.filter(data => data.tag === filter) :
    filter === productFilter.SPARKLING ? products.filter(data => data.tag === filter) :
    products;

  const left = data.map((item, index) => {
    if (index % 2 !== 0) return;
    return (
      <div key={ item.id }>
        <ProductCard data={ item } callback={() => {
          State.setActiveProduct(item.id);
          State.setScreen(screen.PRODUCT);
        }} />
      </div>
    );
  });

  const right = data.map((item, index) => {
    if (index % 2 === 0) return;
    return (
      <div key={ item.id }>
        { index === 1 && <div className='products-card-indent'></div> }
        <ProductCard data={ item } callback={() => {
          State.setActiveProduct(item.id);
          State.setScreen(screen.PRODUCT);
        }} />
      </div>
    );
  });

  return (
    <>
      <div className='products-block'>
        <BackButton callback={() => State.toBackScreen()} />
        <HeaderLogo />
        <div className='products-title-block'>продукция</div>
        <div className='products-content-block'>
          <div className='products-descr'>Откройте для себя мир Santo Stefano - разнообразие слабоалкогольных и безалкогольных газированных напитков, игристых вин и аперитивов.<br /><br />Идеальный выбор для любого случая, будь то торжественное событие или уютный вечер в кругу друзей.</div>
          <div className='products-switch-content'>
            <div className='products-switch-block'>
              <div
                className={'products-switch-button-' + (filter === productFilter.ALL ? 'enable' : 'disable')}
                onClick={() => setFilter(productFilter.ALL)}
              ><div>Все</div></div>
              <div
                className={'products-switch-button-' + (filter === productFilter.SPARKLING ? 'enable' : 'disable')}
                onClick={() => setFilter(productFilter.SPARKLING)}
              ><div>Игристое</div></div>
              <div
                className={'products-switch-button-' + (filter === productFilter.NON_ALCOHOLIC ? 'enable' : 'disable')}
                onClick={() => setFilter(productFilter.NON_ALCOHOLIC)}
              ><div>Безалкогольное</div></div>
              <div
                className={'products-switch-button-' + (filter === productFilter.VERMOUTH ? 'enable' : 'disable')}
                onClick={() => setFilter(productFilter.VERMOUTH)}
              ><div>Вермут</div></div>
            </div>
          </div>
          <div className='products-card-block'>
            <div className='products-row'>{ left }</div>
            <div className='products-row'>{ right }</div>
          </div>
        </div>
        <div className='products-tabbar-indent'></div>
      </div>
      <Tabbar opacity={ 1 } />
    </>
  );
});