import '../../css/about.css';
import { observer } from 'mobx-react-lite';
import HeaderLogo from '../components/HeaderLogo';
import BackButton from '../components/BackButton';
import Tabbar from '../components/Tabbar';
import CarouselProducts from '../components/CarouselProducts';
import CarouselMerch from '../components/CarouselMerch';
import State from '../data/State';
import { productFilter, screen } from '../types/enums';

export default observer(() => {
  return (
    <>
      <div className='about-block'>
        <BackButton callback={() => State.toBackScreen()} />
        <HeaderLogo />
        <div className='about-title-block'>История<br /><span>бренда</span></div>
        <div className='about-content-block'>
          <div className='about-descr'>В 2007 году, в самом сердце Италии, в живописной деревне Санто Стефано, знаменитый энолог Antonio Maccieri задумался над созданием шедевра, который стал бы символом итальянской легкости и элегантности.<br /><br />В 2014 году мечта воплотилась в жизнь с появлением игристого напитка Santo Stefano. Соблюдая высокие стандарты качества, бренд объединил в себе традиции и лучшие европейские ингредиенты.<br /><br />Сегодня Santo Stefano – это слабоалкогольные и безалкогольные газированные напитки, аперитивы и игристые вина. Вся продукция произведена под контролем European Wines and Spirits Led в Аграте Брианца, Италия.<br /><br /> Santo Stefano — это бренд №1 в категории игристых вин. Праздник по-итальянски в каждом бокале!<br /><br />La festa Italiana!</div>
          <div className='about-products-title-block'>
            <div className='about-products-title'>Продукция</div>
            <div className='about-products-title-all' onClick={() => {
              State.setProductFilter(productFilter.ALL);
              State.setScreen(screen.PRODUCTS);
            }}>Все</div>
          </div>
          <CarouselProducts />
          <div className='about-products-title-block'>
            <div className='about-products-title'>Рекомендации</div>
            <div className='about-products-title-all' onClick={() => State.setScreen(screen.STORE)}>Все</div>
          </div>
          <CarouselMerch />
          <div className='about-team-block'>
            <div className='about-team-title'>Стать частью<br />команды</div>
            <div className='about-team-santo'>Santo stefano</div>
            <div className='about-team-work-header'>Хотите работать с нами?</div>
            <div className='about-team-work-descr'>Оставляйте анкету и мы обязательно<br />свяжемся с вами</div>
            <div className='about-questionnaire-button-block'>
              <div className='about-questionnaire-button' onClick={() => State.setScreen(screen.QUESTIONNAIRE)}>ЗАПОЛНИТЬ АНКЕТУ</div>
            </div>
          </div>
          <div className='about-tabbar-indent'></div>
        </div>
      </div>
      <Tabbar opacity={ 1 } />
    </>
  );
});