import '../../css/scan-result.css';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import State from '../data/State';
import { screen } from '../types/enums';

type props = {
  callback: Function;
  success: boolean;
}

export default (props: props) => {
  const header = props.success ?
    <div className='scan-result-header'>ЧЕК ОТСКАНИРОВАН<br />УСПЕШНО!</div> :
    <div className='scan-result-header'>ОШИБКА. ЧЕК НЕ ПРИНЯТ</div>

  const text = props.success ?
    <div>Поздравляем, чек отсканирован!<br /><br />Баллы за чек будут зачислены с минуты на минуту в личном кабинете в разделе “Клуб”.</div> :
    <div>Что-то пошло не так. Чек не был принят. Возможно, чек уже был использован, либо в чеке не содержится позиции Santo Stefano. Попробуйте еще раз или сообщите нам о проблеме.</div>

  const button = props.success ?
    <div className='white-button' onClick={() => State.setScreen(screen.MOVEMENTS)}>История начислений</div> :
    <div className='white-button' onClick={() => State.setScreen(screen.SUPPORT)}>Мне нужна помощь</div>
    
  return (
    <div className='scan-result-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='scan-result-header'>{ header }</div>
      <div className='scan-result-text'>{ text }</div>
      <div className='scan-result-buttons-block'>
        <div className='gold-button' onClick={() => props.callback()}>Сканировать ещё один чек</div>
        { button }
        <div className='white-button' onClick={() => State.setScreen(screen.MAIN)}>На главную</div>
      </div>
    </div>
  );
}