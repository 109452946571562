import '../../css/tabbar.css';
import { observer } from 'mobx-react-lite';
import State from '../data/State';
import User from '../data/User';
import { screen } from '../types/enums';

export default observer((props: { opacity: number }) => {
  const { opacity } = props;
  const setScreen = (screen: screen) => {
    if (opacity === 0) return;
    State.setScreen(screen);
  }
  const tab2pages = [
    screen.ABOUT,
    screen.PRODUCTS,
    screen.PRODUCT,
    screen.FAVORITES,
    screen.RECIPE,
    screen.ORDER,
    screen.STATUSES,
    screen.DATA,
    screen.ORDERS,
    screen.MOVEMENTS,
    screen.FAQ,
    screen.SUPPORT,
  ];
  const tab2page = typeof tab2pages.find(data => data === State.getScreen()) === 'number';

  const tab4pages = [
    screen.STORE,
    screen.CART,
    screen.MERCH
  ];
  const tab4page = typeof tab4pages.find(data => data === State.getScreen()) === 'number';

  const cursor = opacity > 0 ? 'pointer' : 'auto';
  const tab1 = State.getScreen() === screen.MAIN ? ' tab-1-active' : '';
  const tab2 = tab2page ? ' tab-2-active' : '';
  const tab3 = State.getScreen() === screen.CLUB ? ' tab-3-active' : '';
  const tab4 = tab4page ? ' tab-4-active' : '';

  return (
    <div className='tabbar-block' style={{ opacity, display: opacity === 0 && 'none' }}>
      <div className='tabbar'>
        <div style={{ cursor }} className={'tabbar-button-1' + tab1} onClick={() => setScreen(screen.MAIN)}></div>
        <div style={{ cursor }} className={'tabbar-button-2' + tab2} onClick={() => setScreen(screen.ABOUT)}></div>
        <div style={{ cursor }} className='tabbar-button-center' onClick={() => {
          User.isRegistered() ? setScreen(screen.SCAN_CHOOSE) : setScreen(screen.REG);
        }}></div>
        <div style={{ cursor }} className={'tabbar-button-3' + tab3} onClick={() => setScreen(screen.CLUB)}></div>
        <div style={{ cursor }} className={'tabbar-button-4' + tab4} onClick={() => setScreen(screen.STORE)}></div>
      </div>
    </div>
  );
});