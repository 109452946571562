import { makeAutoObservable } from 'mobx';

class User {
  constructor() {
    makeAutoObservable(this);
  }

  private _initData: string;
  private _id: number;
  private _username: string;
  private _firstname: string;
  private _lastname: string;
  private _avatar: string;
  private _loaded: boolean;
  private _scores: number;
  private _name: string = '';
  private _mail: string = '';
  private _phone: string = '';
  private _birthday: string = '';
  private _city: string = '';

  /**
   * Данные инициализации
   */
  public getInitData(): string {
    return this._initData;
  }

  public setInitData(data: string): void {
    this._initData = data;
  }

  /**
   * ID Telegram
   */
  public setID(id: number): void {
    this._id = id;
  }

  public getID(): number {
    return this._id;
  }

  /**
   * username из Telegram
   */
  public setUsername(username: string): void {
    this._username = username;
  }

  public getUsername(): string {
    return this._username;
  }

  /**
   * Имя из Telegram
   */
  public setFirstName(firstname: string): void {
    this._firstname = firstname;
  }

  public getFirstName(): string {
    return this._firstname;
  }

  /**
   * Фамилия из Telegram
   */
  public setLastName(lastname: string): void {
    this._lastname = lastname;
  }

  public getLastName(): string {
    return this._lastname;
  }

  /**
   * Аватар пользователя (по факту статичный, телега не выдаёт).
   */
  public setAvatar(avatar: string): void {
    this._avatar = avatar;
  }

  public getAvatar(): string {
    return this._avatar;
  }

  /**
   * Состояние загрузки пользовательских данных
   */
  public setLoaded(): void {
    this._loaded = true;
  }

  public isLoaded(): boolean {
    return this._loaded;
  }

  /**
   * Накопленные баллы
   */
  public getScores(): number {
    return this._scores;
  }

  public setScores(scores: number): void {
    this._scores = scores;
  }

  /**
   * Имя при регистрации
   */
  public getName(): string {
    return this._name;
  }

  public setName(name: string): void {
    this._name = name;
  }

  /**
   * Email при регистрации
   */
  public getMail(): string {
    return this._mail;
  }

  public setMail(mail: string): void {
    this._mail = mail;
  }

  /**
   * Телефон при регистрации
   */
  public getPhone(): string {
    return this._phone;
  }

  public setPhone(phone: string): void {
    this._phone = phone;
  }

  /**
   * Дата рождения при регистрации
   */
  public getBirthday(): string {
    return this._birthday;
  }

  public setBirthday(birthday: string): void {
    this._birthday = birthday;
  }

  /**
   * Горож при регистрации
   */
  public getCity(): string {
    return this._city;
  }

  public setCity(city: string): void {
    this._city = city;
  }

  /**
   * Состояние регистрации
   */
  public isRegistered(): boolean {
    if (this._name === '' ||
    this._mail === '' ||
    this._phone === '' ||
    this._birthday === '' ||
    this._city === '') {
      return false;
    }
    return true;
  }
}

export default new User();