import '../../css/main.css';
import { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { screen } from '../types/enums';
import Stories from '../components/Stories';
import Tabbar from '../components/Tabbar';
import Utils from '../data/Utils';
import User from '../data/User';
import State from '../data/State';

import poster from '../../assets/media/main/poster.jpg';
import video from '../../assets/media/main/video.mp4';

const MAX_OPACITY_SCROLL = 250;
const MAX_VIDEO_VISIBLE_SCROLL = 800;

export default observer(() => {
  const [opacityHeader, setOpacityHeader] = useState(1);
  const [opacityTabbar, setOpacityTabbar] = useState(0);
  const [displayVideo, setDisplayVideo] = useState('block');
  const videoRef = useRef(null);

  useEffect(() => {
    const root = document.querySelector('#root');
    root.addEventListener('scroll', () => {
      const scrollTop = root.scrollTop;
      const top = scrollTop > MAX_OPACITY_SCROLL ? MAX_OPACITY_SCROLL : scrollTop;
      setOpacityHeader(1 - top / MAX_OPACITY_SCROLL);
      setOpacityTabbar(top / MAX_OPACITY_SCROLL);

      scrollTop > MAX_VIDEO_VISIBLE_SCROLL && setDisplayVideo('none');
      scrollTop < MAX_VIDEO_VISIBLE_SCROLL && setDisplayVideo('block');
      
      videoRef.current?.paused && videoRef.current?.load();
    });
    root.addEventListener('pointerdown', () => {
      videoRef.current?.paused && videoRef.current?.load();
    });
  }, []);
  
  const stories = State.getStories().map((data, indext) => {
    return (
      <div key={ data.id } className='main-story-block' onClick={() => {
        State.setActiveStory(indext);
        State.setShowStories(true);
      }}>
        <div className='main-story-icon' style={{ backgroundImage: 'url(' + data.icon + ')' }}></div>
        <div className='main-story-name'>{ data.name }</div>
      </div>
    );
  });
  return (
    <>
      <div className='main-stories-block'>
        <video style={{ display: displayVideo }} ref={ videoRef } muted={ User.getID() === 0 ? true : State.isShowStories() } className='main-video' playsInline autoPlay loop poster={ poster }>
          <source src={ video } type='video/mp4' />
        </video>
      </div>
      <div
        style={{
          opacity: opacityHeader,
          display: opacityHeader === 0 && 'none'
        }}
        className='main-header-block'
      >
        <div className='main-avatar-block'>
          <div
            className='main-avatar'
            style={{ backgroundImage: 'url(' + User.getAvatar() + ')' }}
            onClick={() => State.setScreen(screen.CLUB)}
          ></div>
        </div>
        <div className='main-logo'></div>
        <div className='main-scores'>{ Utils.formatSum(User.getScores()) } Б</div>
      </div>
      <div className='main-indent-block'></div>
      <div className='main-info-block'>
        <div className='main-carousel-block'>{ stories }</div>
        <div className='main-contest-block'>
          <div className='main-contest-header'>ВРЕМЯ<br /><span>подарков</span></div>
          <div className='main-contest-descr'>Выиграй iPhone 15 и другие призы<br />вместе с Santo Stefano Zero</div>
          <div className='main-contest-button' onClick={() => State.setScreen(screen.CONTEST)}></div>
        </div>
        <div className='main-store-block'>
          <div className='main-store-header'>эксклюзивные<br /><span>призы</span></div>
          <div className='main-store-descr'>Коллекция мерча<br />и многое другое</div>
          <div className='main-store-button-block'>
            <div className='main-store-button' onClick={() => State.setScreen(screen.STORE)}></div>
          </div>
        </div>
        <div className='main-scan-block'>
          <div className='main-scan-header'>программа<br /><span>лояльности</span></div>
          <div className='main-scan-descr'>Сканируйте чеки<br />и получайте баллы</div>
          <div className='main-scan-button-block'>
            <div className='main-scan-button' onClick={() => {
              User.isRegistered() ? State.setScreen(screen.SCAN_CHOOSE) : State.setScreen(screen.REG);
            }}></div>
          </div>
        </div>
        {/* <div className='main-game-block'>
          <div className='main-game-header'>Праздник<br /><span>По-Итальянски</span></div>
          <div className='main-game-descr'>играйте в нашу<br />праздничную игру<br />и зарабатывайте<br />еще больше баллов</div>
          <div className='main-game-button-block'>
            <div className='main-game-button' onClick={() => {
              console.log('Игра');
            }}></div>
          </div>
        </div> */}
        <div className='main-tabbar-indent'></div>
      </div>
      <Tabbar opacity={ opacityTabbar } />
      { State.isShowStories() && <Stories /> }
    </>
  );
});