import '../../css/movements.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import Status from '../components/Status';
import User from '../data/User';
import State from '../data/State';
import { status, screen } from '../types/enums';

export default observer(() => {
  const [movements, setMovements] = useState<Imovement[]>(null);
  useEffect(() => {
    axios.post(process.env.REACT_APP_API + '/getMovements', {
      init_data: User.getInitData(),
      id: User.getID()
    }).then(res => {
      if (!res.data.error) {
        setMovements(res.data.data);
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
    }).catch(e => State.setScreen(screen.ERROR));
  }, []);

  const list = movements && movements.map((data, i) => {
    const status = data.scores > 0 ? 'Начислено' : 'Потрачено';
    if (data.scores === 0) return null;
    return (
      <div key={ i } className='movements-row'>
        <div>{ data.time }</div>
        <div>{ data.scores }Б</div>
        <div style={{ color: data.scores > 0 && '#219F2D' }}>{ status }</div>
      </div>
    );
  });

  return (
    <div className='movements-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='movements-header-indent'></div>
      {/* <Status status={ status.GOLD } /> */}
      <div className='movements-content-block'>
        <div className='movements-row'>
          <div>Дата</div>
          <div>Сумма</div>
          <div>Статус</div>
        </div>
        { list }
        { list && list.length === 0 && <div className='movements-empty'>История начислений пуста</div> }
      </div>
      <div className='movements-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
    </div>
  );
});