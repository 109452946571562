import '../../css/product.css';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import products from '../data/products';
import recipes from '../data/recipes';
import State from '../data/State';
import Profile from '../data/Profile';
import { screen } from '../types/enums';

export default observer(() => {
  const carousel = useRef<HTMLDivElement>();
  const product = products.find(data => data.id === State.getActiveProduct());
  const favorite = Boolean(Profile.getProductFavorites().find(data => data === product.id));
  const favIcon = favorite ? 'enable' : 'disable';

  const recommendations = recipes.map(data => {
    return (
      <div key={ data.id } className='product-recommendations-item' onClick={() => {
        const root = document.querySelector('#root');
        root.scrollTop = 0;
        carousel.current.scrollLeft = 0;
        State.setActiveRecipe(data.id);
        State.setScreen(screen.RECIPE);
      }}>
        <div className='product-recommendations-item-image' style={{ backgroundImage: 'url(' + data.image + ')' }}></div>
        <div className='product-recommendations-item-text'>{ data.shortName.toUpperCase() }</div>
      </div>
    );
  });

  const font = product.label === 'Безалкогольное' ? '48px' : '70px';

  return (
    <>
      <div className='product-block'>
        <HeaderLogo />
        <BackButton callback={() => State.toBackScreen()} />
        <div className={'prouct-fav-icon ' + favIcon} onClick={() => favorite ? Profile.removeProductFavorite(product.id) : Profile.addProductFavorite(product.id)}></div>
        <div className='product-header-indent'></div>
        <div className='product-cover-block'>
          <div className='product-cover-label' style={{ fontSize: font }}>{ product.label.toUpperCase() }</div>
          <div className='product-cover-descr'>
            <div className='product-cover-descr-name' dangerouslySetInnerHTML={{ __html: product.name }}></div>
            <div className='product-cover-descr-item'>Цвет:<br />{ product.color }</div>
            {/* <div className='product-cover-descr-item'>Крепость:<br />{ product.alcohol }</div> */}
            {/* <div className='product-cover-descr-item'>Вид:<br />{ product.type }</div> */}
            <div className='product-cover-descr-item'>Вкус:<br />{ product.taste }</div>
            <div className='product-cover-descr-item'>Букет:<br />{ product.descr }</div>
          </div>
          <div className='product-cover-image' style={{ backgroundImage: 'url(' + product.image + ')' }}></div>
        </div>
        <div className='product-content-block'>
          <div className='product-descr' dangerouslySetInnerHTML={{ __html: product.bigDescr }}></div>
          <div className='product-recommendations-title'>Рекомендации</div>
          <div className='product-recommendations-block' ref={ carousel }>{ recommendations }</div>
        </div>
        <div className='product-tabbar-indent'></div>
      </div>
      <Tabbar opacity={ 1 } />
    </>
  );
});