import '../../css/recipe.css';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import recipes from '../data/recipes';
import State from '../data/State';
import Profile from '../data/Profile';

export default observer(() => {
  const carousel = useRef<HTMLDivElement>();
  const video = useRef<HTMLVideoElement>();
  const [isPlaying, setIsPlaying] = useState(false);
  const recipe = recipes.find(data => data.id === State.getActiveRecipe());
  const favorite = Boolean(Profile.getRecipeFavorites().find(data => data === recipe.id));
  const favIcon = favorite ? 'enable' : 'disable';

  const recommendations = recipes.map(data => {
    return (
      <div key={ data.id } className='product-recommendations-item' onClick={() => {
        const root = document.querySelector('#root');
        setIsPlaying(false);
        root.scrollTop = 0;
        carousel.current.scrollLeft = 0;
        State.setActiveRecipe(data.id);
      }}>
        <div className='product-recommendations-item-image' style={{ backgroundImage: 'url(' + data.image + ')' }}></div>
        <div className='product-recommendations-item-text'>{ data.shortName.toUpperCase() }</div>
      </div>
    );
  });

  return (
    <>
      <div className='recipe-block'>
        <HeaderLogo />
        <BackButton callback={() => State.toBackScreen()} />
        <div className={'recipe-fav-icon ' + favIcon} onClick={() => favorite ? Profile.removeRecipeFavorite(recipe.id) : Profile.addRecipeFavorite(recipe.id)}></div>
        <div className='recipe-header-indent'></div>
        <div className='recipe-cover-block'>
          <div className='recipe-cover-label'>РЕЦЕПТЫ</div>
          <div className='recipe-cover-descr'>
            <div className='recipe-cover-descr-name'>{ recipe.name.toUpperCase() }</div>
            <div className='recipe-cover-descr-item'>КРЕПОСТЬ:<br />{ recipe.alcohol }</div>
            <div className='recipe-cover-descr-item'>СЛОЖНОСТЬ:<br />{ recipe.complexity }</div>
            <div className='recipe-cover-descr-item'>ВРЕМЯ:<br />{ recipe.time }</div>
            { recipe.taste && <div className='recipe-cover-descr-item'>ВКУС:<br />{ recipe.taste }</div> }
          </div>
          <div className='recipe-cover-image' style={{ backgroundImage: 'url(' + recipe.image + ')' }}></div>
        </div>
        <div className='recipe-content-block'>
          <div className='recipe-descr-header'>Ингредиенты</div>
          <div className='recipe-descr-text' dangerouslySetInnerHTML={{ __html: recipe.ingredients }}></div>
          <div className='recipe-descr-header'>Способ приготовления</div>
          <div className='recipe-descr-text'>{ recipe.descr }</div>
          { recipe.video && <div className='recipe-video-title'>Пошаговый<br />рецепт коктейля</div> }
          { recipe.video && <div className='recipe-video-container'>
            <video ref={ video } poster={ recipe.poster } onEnded={() => setIsPlaying(false)}>
              <source src={ recipe.video } type='video/mp4' />
            </video>
            { !isPlaying && <div className='recipe-video-play-btn' onClick={() => {
              setIsPlaying(true);
              video.current.play();
            }}></div> }
          </div> }
          <div className='recipe-recommendations-title'>Рекомендации</div>
          <div className='recipe-recommendations-block' ref={ carousel }>{ recommendations }</div>
        </div>
        <div className='recipe-tabbar-indent'></div>
      </div>
      <Tabbar opacity={ 1 } />
    </>
  );
});