import '../../css/reg.css';
import axios from 'axios';
import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import State from '../data/State';
import User from '../data/User';
import Utils from '../data/Utils';
import { screen } from '../types/enums';

enum page {
  TEXT,
  DATA
}

export default observer(() => {
  const [dataPage, setDataPage] = useState(page.TEXT);
  const [showModal, setShowModal] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('+7');
  const [birthday, setBirthday] = useState('');
  const [city, setCity] = useState('');
  
  const [mailError, setMailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [birthdayError, setBirthdayError] = useState(false);

  const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegExp = /^\+7\d{10}$/;

  const reg = () => {
    const testMail = emailRegExp.test(mail);
    const testPhone = phoneRegExp.test(phone);
    const testBirthday = Utils.isValidDate(birthday);
    !testMail && setMailError(true);
    !testPhone && setPhoneError(true);
    !testBirthday && setBirthdayError(true);

    if (testMail && testPhone && testBirthday) {
      axios.post(process.env.REACT_APP_API + '/sendRegData', {
        init_data: User.getInitData(),
        id: User.getID(),
        name: name,
        mail: mail,
        phone: phone,
        birthday: birthday,
        city: city
      }).then(res => {
        if (!res.data.error) {
          User.setName(name);
          User.setMail(mail);
          User.setPhone(phone);
          User.setBirthday(birthday);
          User.setCity(city);
          State.setScreen(screen.MAIN);
        } else {
          State.setError(res.data.error_type);
          State.setScreen(screen.ERROR);
        }
      }).catch(e => State.setScreen(screen.ERROR));
    }
  }

  const setFormatPhone = (phone: string) => {
    if (phone.length < 3) {

      if (phone !== '+7' && phone !== '+' && phone !== '') {
        phone = '+7';
      }
    } else {

      if (phone[0] !== '+') {
        phone = '+' + phone;
      }

      if (phone[1] !== '7') {
        phone = phone[0] + '7' + phone.slice(2);
      }
      phone = '+7' + phone.slice(2).replace(/[^0-9]/g, '');

      if (phone.length > 12) {
        phone = phone.slice(0, 12);
      }
    }
    setPhone(phone);
  }

  const regActive = Boolean(name && mail && phone && birthday && city && agreement);

  const text = (
    <>
      <div className='reg-text'>Давайте познакомимся?<br /><br />Чтобы пользоваться всеми функциями сервисного приложения, необходимо пройти регистрацию.<br /><br /><br />Я принимаю <a href={ State.AGREEMENT } target='_blank'>пользовательское соглашение</a> и согласен с <a href={ State.PRIVACY } target='_blank'>правилами использования и обработки персональных данных</a></div>
      <div className='reg-buttons-block'>
        <div className='gold-button' onClick={() => setDataPage(page.DATA)}>Согласен</div>
        <div className='white-button' onClick={() => {
          // setShowModal(true);
          // 'Не согласен'
          State.setScreen(screen.MAIN);
        }}>На главный</div>
      </div>
    </>
  );

  const data = (
    <div className='reg-data-block'>
      <div className='reg-input-block'>
        <div className='reg-input-label'>Имя</div>
        <input className='reg-input' value={ name } onChange={e => setName(e.target.value)} placeholder='Мария' type='text' />
      </div>
      <div className='reg-input-block'>
        <div className='reg-input-label'>email</div>
        <input className={'reg-input' + (mailError ? ' reg-error' : '')} value={ mail } onChange={e => {
          setMailError(false);
          setMail(e.target.value);
        }} placeholder='example@mail.ru' type='text' />
      </div>
      <div className='reg-input-block'>
        <div className='reg-input-label'>Телефон</div>
        <input className={'reg-input' + (phoneError ? ' reg-error' : '')} value={ phone } onChange={e => {
          setPhoneError(false);
          setFormatPhone(e.target.value);
        }} placeholder='+79000000000' type='text' />
      </div>
      <div className='reg-input-block'>
        <div className='reg-input-label'>Дата рождения</div>
        <input className={'reg-input' + (birthdayError ? ' reg-error' : '')} value={ birthday } onChange={e => {
          setBirthdayError(false);
          let text = e.target.value;
          if ((birthday.length === 1 && text.length === 2) || (birthday.length === 4 && text.length === 5)) {
            text += '.';
          }
          if (birthday.length === 2 && text.length === 3) {
            text = text.slice(0, 2) + '.' + text.slice(-1);
          }
          if (birthday.length === 5 && text.length === 6) {
            text = text.slice(0, 5) + '.' + text.slice(-1);
          }
          if (text.length > 10) {
            text = text.slice(0, 10);
          }
          setBirthday(text);
        }} placeholder='01.01.2000' type='text' />
      </div>
      <div className='reg-input-block'>
        <div className='reg-input-label'>Город</div>
        <input className='reg-input' value={ city } onChange={e => setCity(e.target.value)} placeholder='Москва' type='text' />
      </div>
      <div className='reg-checkbox-block'>
        <div className={'reg-checkbox reg-checkbox-' + (agreement ? 'enable' : 'disable')} onClick={() => setAgreement(!agreement)}></div>
        <a href={ State.AGREEMENT } target='_blank'>Пользовательское соглашение</a>
      </div>
      <div className='reg-button-block'>
        <div className='gold-button' style={{ opacity: regActive ? 1 : .5 }} onClick={() => {
          if (!regActive) return;
          reg();
        }}>Зарегистрироваться</div>
      </div>
    </div>
  );

  const modal = (
    <div className='reg-modal-block'>
      <div className='reg-modal'>
        <div className='reg-modal-header'>Заголовок</div>
        <div className='reg-modal-text'>Текст, о принятии согласия</div>
        <div className='reg-modal-buttons'>
          <div onClick={() => State.setScreen(screen.MAIN)}>На главную</div>
          <div className='reg-modal-button-gold' onClick={() => {
            setShowModal(false);
            setDataPage(page.DATA);
          }}>Согласен</div>
        </div>
      </div>
    </div>
  );

  return (
    <div className='reg-block'>
      <BackButton callback={() => {
        dataPage === page.TEXT ? State.toBackScreen() : setDataPage(page.TEXT);
      }} />
      <HeaderLogo />
      <div className='reg-header'>РЕГИСТРАЦИЯ</div>
      { dataPage === page.TEXT ? text : data }
      { showModal && modal }
    </div>
  );
});